/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import {
  Button, Divider, Typography, IconButton, Modal, Box, Avatar, InputAdornment, Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import PitchbookingTeamMemberSelector from './components/PitchbookingTeamMemberSelector';
import { getCompanies } from '../../services/companiesServices';
import { categoryOptions, regionOptions, sourceOptions } from './helper';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

const DISPLAY_DATE_FORMAT = 'DD-MMM-YYYY';
const formatDateForDisplay = (date) => (date ? moment(date).format(DISPLAY_DATE_FORMAT) : '');

export const SalesDetails = ({ companyInfo, updateCompany }) => {
  const dispatch = useDispatch();
  const { id, accounting } = companyInfo;

  // Local state for the accounting fields
  const [category, setCategory] = useState('');
  const [region, setRegion] = useState('');
  const [source, setSource] = useState('');
  const [referredByCompanyId, setReferredBy] = useState(null);
  const [referredByNote, setReferredByNote] = useState('');
  const [companies, setCompanies] = useState([]);
  const [hubspotFirstContact, setHubspotFirstContact] = useState('');
  const [firstContact, setFirstContact] = useState('');
  const [dealOwner, setDealOwner] = useState('');
  const [openModal, setOpenModal] = useState(false); // Modal open state

  // Accessing pbAdmins (team members) from Redux state
  const pbAdmins = useSelector((state) => state.companies.companyInfo?.pbAdmins || []);

  // Fetch companies for the autocomplete
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const res = await getCompanies();
        const sortedCompanies = res.data.sort((a, b) => a.name.localeCompare(b.name));
        setCompanies(sortedCompanies);
      } catch (err) {
        console.error(err);
      }
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (accounting) {
      setCategory(accounting.category || '');
      setRegion(accounting.region || '');
      setSource(accounting.source || '');
      setReferredBy(accounting.referredByCompanyId || null);
      setReferredByNote(accounting.referredByNote || '');
      setHubspotFirstContact(accounting.hubspotFirstContact || '');
      setFirstContact(accounting.firstContact || '');
      setDealOwner(accounting.dealOwner || '');
    }
  }, [accounting]);

  const handleSave = () => {
    const updatedAccounting = {
      category,
      region,
      source,
      referredByCompanyId,
      referredByNote,
      hubspotFirstContact,
      firstContact,
      dealOwner,
    };

    dispatch(updateCompany(id, {
      accounting: {
        ...accounting,
        ...updatedAccounting,
      },
    }));
    setOpenModal(false); // Close modal after saving
  };

  const getLabelFromValue = (options, value) => {
    const foundOption = options.find((option) => option.value === value);
    return foundOption ? foundOption.label : 'Not Set';
  };

  const getDealOwnerName = (dealOwnerId) => {
    const owner = pbAdmins.find((admin) => admin.userId === dealOwnerId);
    return owner ? `${owner.firstName} ${owner.lastName}` : 'Not Set';
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" style={{ textDecoration: 'underline' }}>
          Sales Details
        </Typography>
        <IconButton onClick={() => setOpenModal(true)}>
          <EditIcon />
        </IconButton>
      </Box>

      {/* Condensed Summary View */}
      <Box display="flex" flexDirection="column" gap="0.5rem">
        <Typography>
          <strong>Category:</strong>
          {' '}
          {getLabelFromValue(categoryOptions, category)}
        </Typography>
        <Typography>
          <strong>Region:</strong>
          {' '}
          {getLabelFromValue(regionOptions, region)}
        </Typography>
        <Typography>
          <strong>Source:</strong>
          {' '}
          {getLabelFromValue(sourceOptions, source)}
        </Typography>
        <Typography>
          <strong>Referred By:</strong>
          {' '}
          {referredByCompanyId
            ? companies.find((company) => company.id === referredByCompanyId)?.name
            : referredByNote || 'Not Referred'}
          {referredByCompanyId && referredByNote ? ` (${referredByNote})` : ''}
        </Typography>
        <Typography>
          <strong>First Contact:</strong>
          {' '}
          {(() => {
            if (firstContact) {
              return hubspotFirstContact
                ? `${formatDateForDisplay(firstContact)} (HubSpot, ${formatDateForDisplay(hubspotFirstContact)})`
                : `${formatDateForDisplay(firstContact)}`;
            } if (hubspotFirstContact) {
              return `${formatDateForDisplay(hubspotFirstContact)} (from Hubspot)`;
            }
            return 'Not Set';
          })()}
        </Typography>
        <Typography>
          <strong>Deal Owner:</strong>
          {' '}
          {getDealOwnerName(dealOwner)}
        </Typography>
      </Box>

      {/* Modal for Editing Fields */}
      <Modal open={openModal} onClose={() => setOpenModal(false)} aria-labelledby="edit-sales-details">
        <Box sx={modalStyle}>
          <Typography id="edit-sales-details" variant="h6" component="h2" gutterBottom>
            Edit Sales Details
          </Typography>

          <Box mb={2}>
            <PBInput
              id="category"
              type="select"
              label="Category"
              options={categoryOptions}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </Box>

          <Box mb={2}>
            <PBInput
              id="region"
              type="select"
              label="Region"
              options={regionOptions}
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            />
          </Box>

          <Box mb={2}>
            <PBInput
              id="source"
              type="select"
              label="Source"
              options={sourceOptions}
              value={source}
              onChange={(e) => setSource(e.target.value)}
            />
          </Box>

          <Box mb={2}>
            <Autocomplete
              id="referredByCompanyId"
              options={[{ label: 'Not Referred', value: null }, ...companies.map((company) => ({ label: company.name, value: company.id }))]}
              getOptionLabel={(option) => option.label || ''}
              renderOption={(option) => (
                <>
                  {option.logoUrl && <Avatar src={option.logoUrl} style={{ marginRight: 8 }} />}
                  {option.label}
                </>
              )}
              value={referredByCompanyId ? { label: companies.find((company) => company.id === referredByCompanyId)?.name, value: referredByCompanyId } : { label: 'Not Referred', value: null }}
              onChange={(event, newValue) => setReferredBy(newValue ? newValue.value : null)}
              renderInput={(params) => <TextField {...params} label="Referred By" variant="outlined" />}
            />
          </Box>

          <Box mb={2}>
            <PBInput
              id="referredByNote"
              type="text"
              label="Referral Note"
              value={referredByNote}
              onChange={(e) => setReferredByNote(e.target.value)}
              fullWidth
            />
          </Box>

          <Box mb={2}>
            <Tooltip title="This date is taken from Hubspot and reflects the first time the contact was created. You can overwrite it below if it's not accurate.">
              <TextField
                label="Hubspot First Contact"
                type="date"
                value={hubspotFirstContact}
                onChange={(e) => setHubspotFirstContact(e.target.value)}
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Tooltip>
          </Box>
          <Box mb={2}>
            <TextField
              label="First Contact"
              type="date"
              value={firstContact || ''}
              onChange={(e) => setFirstContact(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setFirstContact('')}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box mb={2}>
            <PitchbookingTeamMemberSelector
              value={dealOwner}
              onChange={setDealOwner}
              label="Deal Owner"
            />
          </Box>

          <Button variant="contained" color="primary" type="button" style={{ marginTop: '1rem' }} onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Modal>

      <Divider />
    </>
  );
};

SalesDetails.propTypes = {
  companyInfo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    accounting: PropTypes.shape({
      category: PropTypes.string,
      region: PropTypes.string,
      source: PropTypes.string,
      referredByCompanyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      referredByNote: PropTypes.string,
      hubspotFirstContact: PropTypes.string,
      firstContact: PropTypes.string,
      dealOwner: PropTypes.string,
    }),
  }).isRequired,
  updateCompany: PropTypes.func.isRequired,
};

export default SalesDetails;
