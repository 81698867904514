import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import LoadingSection from './LoadingComponents/LoadingSection';
import * as companyServices from '../services/companiesServices';
import { useToast } from '../hooks';

const MediaConfiguration = ({
  type, resourceId, initialImage, reloadResource,
}) => {
  const toast = useToast();
  const company = useSelector((state) => state.companies.companyInfo);
  const [companyMedia, setCompanyMedia] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [bgHoverColor, setBgHoverColor] = useState(null);

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  useEffect(() => {
    setCompanyMedia({ image: initialImage });
  }, [initialImage]);

  if (isLoading) {
    return (<LoadingSection loadingText="Loading please wait..." />);
  }

  const saveCompanyImages = async (values) => {
    setIsLoading(true);
    const reqBody = values;
    reqBody.type = type;
    reqBody.resourceId = resourceId;

    companyServices.updateCompanyResourceMedia(company.id, reqBody, {
      'Content-Type': 'multipart/form-data',
    }).then(
      (res) => {
        if (res.status === 200) {
          toast.trigger({
            type: 'success',
            message: 'Your image has been updated successfully!',
          });
          reloadResource();
          setIsLoading(false);
        }
      },
    ).catch((err) => {
      if (err) {
        setIsLoading(false);
        toast.trigger({
          type: 'error',
          message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us.',
        });
      }
    });
  };

  return (
    <Formik
      initialValues={{
        image: companyMedia?.image || '',
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        try {
          saveCompanyImages(values);
          resetForm();
        } catch (err) {
          toast.trigger({
            type: 'error',
            message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us',
          });
        }
      }}
    >
      {({
        values, errors, touched, handleSubmit, setFieldValue, resetForm, handleBlur,
      }) => (
        <form
          onSubmit={handleSubmit}
          onReset={resetForm}
          style={{
            display: 'flex', flexDirection: 'column', gap: '0.75rem',
          }}
        >
          {/* Title */}
          <Typography variant="h6">Media configuration</Typography>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="body2">
              Please note, when entering your images below, please only use appropriate images.
            </Typography>
            <Typography variant="body2">
              We recommend an image size of 1920 x 1080. In most cases, your image should display
              correctly within these dimensions. Any images bigger or larger than this may appear
              off centre once uploaded.
            </Typography>
          </div>

          {/* Input */}
          <FormControl error={touched.image && errors.image} fullWidth>
            <div style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem',
            }}
            >
              <label htmlFor="image">
                <div
                  title="Click to change image"
                  onMouseEnter={() => {
                    onHover();
                    setBgHoverColor('rgb(163, 162, 160, 0.2)');
                  }}
                  onMouseLeave={() => {
                    onLeave();
                    setBgHoverColor(null);
                  }}
                  className="company-image-upload"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid rgb(163, 162, 160)',
                    borderRadius: '0.75rem',
                    height: '10rem',
                    width: '10rem',
                    cursor: 'pointer',
                    backgroundColor: bgHoverColor,
                    margin: '1rem 0rem',
                  }}
                >
                  {!hover && (values?.image instanceof File || companyMedia?.image) ? (
                    <img
                      src={values?.image instanceof File ? URL.createObjectURL(values?.image) : `${companyMedia?.image}?${performance.now()}`}
                      alt="Company"
                      style={{
                        objectFit: 'contain',
                        width: '250px',
                        height: '250px',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '85%',
                      }}
                    />
                  ) : (
                    //  Upload Image
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 600, color: 'grey', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
                      }}
                    >
                      <AddIcon color="primary" fontSize="large" />
                      {`Click to upload a ${type?.toLowerCase()} image`}
                    </Typography>
                  )}
                  <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      if (e.target.files?.length > 0) {
                        setFieldValue('image', e.target.files[0]);
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              </label>
            </div>
          </FormControl>

          {/* Buttons */}
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '0.75rem',
          }}
          >
            <Button
              type="reset"
              variant="contained"
              style={{ marginTop: '10px', marginBottom: '10px', width: 200 }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: '10px', marginBottom: '10px', width: 200 }}
            >
              Save
            </Button>
          </div>
        </form>

      )}
    </Formik>
  );
};

MediaConfiguration.propTypes = {
  type: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  initialImage: PropTypes.string,
  reloadResource: PropTypes.func.isRequired,
};

MediaConfiguration.defaultProps = {
  initialImage: '',
};

export default MediaConfiguration;
