import React from 'react';
import * as contentful from 'contentful';
import {
  CheckCircleOutlined, ErrorOutlineOutlined, InfoOutlined, ReportProblemOutlined,
  CheckCircleTwoTone, ErrorTwoTone, InfoTwoTone, WarningTwoTone, HelpTwoTone,
} from '@material-ui/icons';

export const getContentfulContnet = (entryId) => {
  const client = contentful.createClient({
    space: 'usvb7o98ifrz',
    accessToken: '07a808cf4fadbdec5e07be8b4ffc784319f4b0c161ee9bf721e6dd730c876118',
  });

  return client.getEntry(entryId)
    .then((entry) => entry.fields.body)
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getLabelIcon = (icon) => {
  switch (icon) {
    case 'success':
      return CheckCircleTwoTone;
    case 'error':
      return ErrorTwoTone;
    case 'warning':
      return WarningTwoTone;
    case 'info':
      return InfoTwoTone;
    case 'help':
      return HelpTwoTone;
    default:
      return HelpTwoTone;
  }
};

export const getAlertIcon = (severity) => {
  switch (severity) {
    case 'success':
      return <CheckCircleOutlined />;
    case 'error':
      return <ErrorOutlineOutlined />;
    case 'warning':
      return <ReportProblemOutlined />;
    case 'info':
      return <InfoOutlined />;
    default:
      return <InfoOutlined />;
  }
};
