import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import * as actions from '../../../../reducers/accessRestrictionsReducer';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  divider: {
    alignSelf: 'stretch',
    height: 'auto',
    margin: theme.spacing(1, 0.5),
  },
  button: {
    width: '150px',
    backgroundColor: '#f0f0f0',
    minHeight: '38px',
  },
}));

const recurrenceToopTip = (recurrence, arTypeTitle) => {
  let text = '';
  switch (recurrence) {
    case 'NONE':
      text = `This ${arTypeTitle} will apply from the first date and first time selected to the last date and last time selected. Please note - every slot between the selected dates and times will be closed and you will be unable to make or accept bookings.`;
      break;
    case 'DAILY':
      text = `This ${arTypeTitle} will apply every day between the selected times, for the chosen dates, inclusive of the first and last date. If you need to exclude a date within this closure, you can use the \u0022Add Exclusions\u0022 on this form to remove any days you do not want this closure to apply to.`;
      break;
    case 'WEEKLY':
      text = `This ${arTypeTitle} will apply weekly on the selected day between the selected times. If you need to exclude a date within this closure, you can use the \u0022Add Exclusions\u0022 on this form to remove any days you do not want this closure to apply to. You must create a separate closure for each individual day you wish to close.`;
      break;
    case 'MONTHLY':
      text = '';
      break;
    case 'ALWAYS':
      text = `Applying this ${arTypeTitle} will close your facility indefinitely and prevent any bookings from being created or applied at all times.`;
      break;
    default:
      break;
  }
  return text;
};

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(2),
    border: 'none',
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const AccessRetrictionRecurrencePicker = (
  {
    accessRestrictions,
    partiallyResetAccessRestrictionCreation,
    updateAccessRestrictionCreation,
    arTypeTitle,
  },
) => {
  const { newAccessRestriction } = accessRestrictions;

  const handleAlignment = (event, newRecurrence) => {
    partiallyResetAccessRestrictionCreation();
    if (newRecurrence === 'DAILY' || newRecurrence === 'WEEKLY') {
      updateAccessRestrictionCreation({
        recurrence: newRecurrence,
        endDateOption: 'NO',
        startDateOption: 'NO',
        weekday: newRecurrence === 'WEEKLY' ? 1 : null,
      });
    } else if (newRecurrence === 'ALWAYS') {
      updateAccessRestrictionCreation({
        recurrence: newRecurrence,
        endDateOption: 'NO',
        startDateOption: 'NO',
        validFrom: null,
        validTo: null,
        startTime: null,
        endTime: null,
        exclusions: [],
      });
    } else {
      updateAccessRestrictionCreation({
        recurrence: newRecurrence,
        endDateOption: 'YES',
        startDateOption: 'YES',
        exclusions: [],
        exclusionsEnabled: false,
      });
    }
  };

  const classes = useStyles();

  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          size="small"
          value={newAccessRestriction.recurrence}
          exclusive
          onChange={handleAlignment}
          aria-label="recurrence"
        >
          <ToggleButton className={classes.button} value="NONE" aria-label="one time recurrence">
            Continuous
          </ToggleButton>
          <ToggleButton className={classes.button} value="DAILY" aria-label="every day recurrence">
            Daily
          </ToggleButton>
          <ToggleButton className={classes.button} value="WEEKLY" aria-label="every week recurrence">
            Every Week
          </ToggleButton>
          <ToggleButton className={classes.button} value="ALWAYS" aria-label="Access-restriction always applies">
            Always
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
      <Alert severity="info">{recurrenceToopTip(newAccessRestriction.recurrence, arTypeTitle)}</Alert>
    </div>
  );
};

AccessRetrictionRecurrencePicker.propTypes = {
  newAccessRestriction: PropTypes.shape().isRequired,
  accessRestrictions: PropTypes.shape().isRequired,
  partiallyResetAccessRestrictionCreation: PropTypes.func.isRequired,
  updateAccessRestrictionCreation: PropTypes.func.isRequired,
  arTypeTitle: PropTypes.string,
};

AccessRetrictionRecurrencePicker.defaultProps = {
  arTypeTitle: 'closure',
};

function mapStateToProps(state) {
  const { accessRestrictions } = state;
  return {
    accessRestrictions,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateAccessRestrictionCreation: (keyWithValue) => dispatch(
    actions.updateAccessRestrictionCreation(keyWithValue),
  ),
  partiallyResetAccessRestrictionCreation: () => dispatch(
    actions.partiallyResetAccessRestrictionCreation(),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessRetrictionRecurrencePicker);
