import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ErrorIcon from '@material-ui/icons/Error';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import Switch from '@material-ui/core/Switch';
import LoadingSection from '../../components/LoadingComponents/LoadingSection';
import * as companyServices from '../../services/companiesServices';
import { useToast, usePitchbookingUser } from '../../hooks';
import { AddDeviceDialog } from './components/AddDeviceDialog';
import EditDeviceNodeDialog from './components/EditDeviceNodeDialog';
import { DeleteDeviceDialog } from './components/DeleteDeviceDialog';

const TerminalConfiguration = () => {
  const toast = useToast();
  const [terminals, setTerminals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isPitchbookingManager, isSuperAdmin } = usePitchbookingUser();
  const { company } = useSelector((state) => (
    {
      company: state.companies.companyInfo,
    }
  ));

  const fetchDevices = async () => {
    try {
      const res = await companyServices.getDevices(company.id, { type: 'TERMINAL', isDeviceSettingsPage: true });
      setTerminals(res.data);
      setIsLoading(false);
    } catch (err) {
      toast.trigger({
        type: 'error',
        message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us',
      });
      console.error(err);
    }
  };

  useEffect(() => {
    if (company.id) {
      setIsLoading(true);
      fetchDevices();
    }
  }, [company.id]);

  const handleDeviceStatusUpdate = async (nodeId, checked) => {
    const status = checked ? 'ACTIVE' : 'INACTIVE';
    setIsLoading(true);
    try {
      const res = await companyServices.updateDeviceStatus(company.id, nodeId, { status });
      if (res.status !== 200) {
        setIsLoading(false);
        toast.trigger({
          type: 'error',
          message: 'Error updating device status',
        });
      } else {
        fetchDevices();
        toast.trigger({
          type: 'success',
          message: 'Device status updated successfully',
        });
      }
    } catch (err) {
      setIsLoading(false);
      toast.trigger({
        type: 'error',
        message: 'Error updating device status',
      });
      console.error(err);
    }
  };

  if (isLoading) {
    return (<LoadingSection loadingText="Loading please wait..." />);
  }

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '1rem',
    }}
    >
      <div>
        <Typography variant="h6" style={{ marginBottom: '1rem' }}>Stripe Terminal Configuration</Typography>
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <AddDeviceDialog
            type="terminal"
            onSuccess={fetchDevices}
          />
        </div>
      </div>

      <AlternativelyVisible condition={terminals && terminals.length > 0}>
        <>
          {terminals.map((node) => (
            <Card
              key={node.id}
            >
              <CardContent>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                >
                  <div>
                    <Typography variant="h5">
                      {node.friendlyName}
                    </Typography>
                    <Typography>{`External ID: ${node.nodeIdentifier}`}</Typography>

                    <Typography style={{ display: 'flex', alignItems: 'center' }}>
                      {`Current Status (${node.status})`}
                      {(isPitchbookingManager || isSuperAdmin) && (
                        <Switch
                          checked={node?.status?.toLowerCase() === 'active'}
                          onChange={(e) => handleDeviceStatusUpdate(node.id, e.target.checked)}
                          color="primary"
                          name="nodeStatus"
                        />
                      )}
                    </Typography>
                  </div>
                </div>
                <div style={{
                  display: 'flex', gap: '1rem', marginTop: '1rem',
                }}
                >
                  <EditDeviceNodeDialog
                    node={node}
                    onSuccess={fetchDevices}
                    scheduleTypeOptions={[]}
                  />
                  {(isPitchbookingManager || isSuperAdmin) && (
                  <DeleteDeviceDialog
                    node={node}
                    onSuccessCallback={fetchDevices}
                  />
                  )}
                </div>
              </CardContent>
            </Card>
          ))}
        </>

        <>
          <Card>
            <CardContent style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
            >
              <ErrorIcon style={{ fontSize: '2rem' }} />
              <Typography variant="subheading">
                You don&apos;t have any terminal devices configured.
                Please contact your account manager for more information.
              </Typography>
            </CardContent>
          </Card>
        </>
      </AlternativelyVisible>
    </div>
  );
};

export default TerminalConfiguration;
