import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible/alternativelyVisible';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import PBButton from '../../components/Button';
import { calculatePitchbookingPrice } from '../../helpers/index';
import '../../styles/billing.css';

const formatTaxRate = (rate) => {
  const percentage = rate * 100;
  if (percentage % 1 === 0) return percentage.toFixed(0);
  if ((percentage * 10) % 1 === 0) return percentage.toFixed(1);
  if ((percentage * 100) % 1 === 0) return percentage.toFixed(2);
  return percentage.toFixed(3);
};

const PriceTable = (props) => {
  const {
    billing,
    currencySym,
    taxRate,
    updateBilling,
    offerValid,
    isPBAdmin,
    open,
    setOpen,
  } = props;

  const {
    internalFacilities, externalFacilities, discount, bulkDiscount,
    offerAmount, offerExpiresAt,
  } = billing;

  const [editing, setEditing] = useState(false);
  const [updatedExternalFacilities, setUpdatedExternalFacilities] = useState(externalFacilities);
  const [updatedInternalFacilities, setUpdatedInternalFacilities] = useState(internalFacilities);
  const [updatedDiscount, setUpdatedDiscount] = useState(discount);
  const [total, setTotal] = useState(0);
  const [totalFacilities, setTotalFacilities] = useState(internalFacilities + externalFacilities);
  const [externalFacilitiesPrice, setExternalFacilitiesPrice] = useState(
    calculatePitchbookingPrice(updatedExternalFacilities, false, 0, bulkDiscount),
  );
  const [internalFacilitiesPrice, setInternalFacilitiesPrice] = useState(
    calculatePitchbookingPrice(
      updatedInternalFacilities, true, updatedExternalFacilities, bulkDiscount,
    ),
  );
  const [subTotal, setSubTotal] = useState(
    externalFacilitiesPrice + internalFacilitiesPrice - discount,
  );
  const [tax, setTax] = useState(subTotal * taxRate);

  useEffect(() => {
    setExternalFacilitiesPrice(
      updatedExternalFacilities
        ? calculatePitchbookingPrice(updatedExternalFacilities, false, 0, bulkDiscount) : 0,
    );
    setInternalFacilitiesPrice(updatedInternalFacilities ? calculatePitchbookingPrice(
      updatedInternalFacilities, true, updatedExternalFacilities, bulkDiscount,
    ) : 0);
    setUpdatedDiscount(updatedDiscount || 0);
    setTotalFacilities(
      (updatedExternalFacilities || 0) + (updatedInternalFacilities || 0),
    );
    setSubTotal(externalFacilitiesPrice + internalFacilitiesPrice - updatedDiscount);

    setTax(parseFloat(subTotal) * taxRate);
    setTotal(subTotal + tax);
  });

  const offerTax = offerAmount * taxRate;
  const offerTotal = offerAmount + offerTax;
  const savings = offerValid ? subTotal - offerAmount : 0;

  return (
    <>
      <PBButton color="secondary" onClick={() => setOpen(!open)}>
        {open ? 'Hide' : 'Show'}
        {' '}
        Price Breakdown
      </PBButton>
      <ConditionallyVisible condition={open}>
        <div className="billingPriceTable" style={{ backgroundColor: '#fdfdfd' }}>
          <ConditionallyVisible condition={isPBAdmin}>
            <div style={{ float: 'right' }}>
              <ConditionallyVisible condition={editing}>
                <PBButton
                  disabled={!editing}
                  color="primary"
                  onClick={() => {
                    updateBilling(
                      {
                        externalFacilities: updatedExternalFacilities || 0,
                        internalFacilities: updatedInternalFacilities || 0,
                        discount: updatedDiscount || 0,
                        amount: subTotal,
                      },
                    );
                    setEditing(false);
                  }}
                >
                  Update Price
                </PBButton>
              </ConditionallyVisible>

              <PBButton
                color={editing ? 'error' : 'secondary'}
                onClick={() => {
                  setEditing(!editing);
                }}
              >
                {editing ? 'Cancel' : 'Edit'}
              </PBButton>

            </div>
          </ConditionallyVisible>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="left" />
                  <TableCell align="center">Facility Count</TableCell>
                  <TableCell align="right">
                    Price
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">External Facilities</TableCell>
                  <AlternativelyVisible condition={isPBAdmin && editing}>
                    <TableCell align="left">
                      <PBInput
                        type="number"
                        value={updatedExternalFacilities}
                        onChange={(e) => {
                          setUpdatedExternalFacilities(
                            parseInt(e.target.value, 10),
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">{externalFacilities}</TableCell>
                  </AlternativelyVisible>
                  <TableCell align="right">
                    {currencySym}
                    {externalFacilitiesPrice ? externalFacilitiesPrice.toFixed(2) : 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Internal Facilities</TableCell>
                  <AlternativelyVisible condition={isPBAdmin && editing}>
                    <TableCell align="left">
                      <PBInput
                        type="number"
                        value={updatedInternalFacilities}
                        onChange={(e) => {
                          setUpdatedInternalFacilities(
                            parseInt(e.target.value, 10),
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">{internalFacilities}</TableCell>
                  </AlternativelyVisible>
                  <TableCell align="right">
                    {currencySym}
                    {internalFacilitiesPrice ? internalFacilitiesPrice.toFixed(2) : 0}
                  </TableCell>
                </TableRow>
                <ConditionallyVisible condition={bulkDiscount}>
                  <TableRow>
                    <TableCell align="left" />
                    <TableCell align="left" />
                    <TableCell align="right"> Bulk Discount Applied</TableCell>
                  </TableRow>
                </ConditionallyVisible>
                <ConditionallyVisible condition={discount > 0 || editing}>
                  <TableRow>
                    <TableCell align="left">Discount</TableCell>
                    <AlternativelyVisible condition={isPBAdmin && editing}>
                      <TableCell align="left">
                        <PBInput
                          type="currency"
                          currencySymbol={currencySym}
                          value={updatedDiscount}
                          onChange={(e) => {
                            setUpdatedDiscount(parseFloat(e.target.value));
                          }}
                        />
                      </TableCell>
                      <TableCell align="left" />
                    </AlternativelyVisible>
                    <TableCell align="right">
                      -
                      {' '}
                      {currencySym}
                      {parseFloat(updatedDiscount, 10).toFixed(2)}
                    </TableCell>
                  </TableRow>
                </ConditionallyVisible>

                {!offerValid ? (
                  <TableRow>
                    <TableCell align="left">Sub Total</TableCell>
                    <TableCell align="left" />
                    <TableCell align="right">
                      {currencySym}
                      {subTotal.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    <TableRow>
                      <TableCell align="left">Monthly fee (Offer)</TableCell>
                      <TableCell align="left" />
                      <TableCell align="right">
                        <span style={{ textDecoration: 'line-through' }}>
                          {currencySym}
                          {(externalFacilitiesPrice + internalFacilitiesPrice).toFixed(2)}
                        </span>
                        {' '}
                        <span style={{ color: 'green', fontWeight: 'bold' }}>
                          {currencySym}
                          {offerAmount.toFixed(2)}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Monthly Savings</TableCell>
                      <TableCell align="left" />
                      <TableCell align="right" style={{ color: 'red' }}>
                        {currencySym}
                        {savings.toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Offer expires on</TableCell>
                      <TableCell align="left" />
                      <TableCell align="right" style={{ fontStyle: 'italic' }}>
                        {moment(offerExpiresAt).format('Do MMM YYYY')}
                      </TableCell>
                    </TableRow>
                  </>
                )}
                <ConditionallyVisible condition={taxRate > 0}>
                  <TableRow>
                    <TableCell align="left">
                      {`VAT (${formatTaxRate(taxRate)}%)`}
                    </TableCell>
                    <TableCell align="left" />
                    <TableCell align="right">
                      {currencySym}
                      {(!offerValid ? tax : offerTax).toFixed(2)}
                    </TableCell>
                  </TableRow>
                </ConditionallyVisible>
                <TableRow>
                  <TableCell align="left" style={{ fontWeight: 800 }}>Total</TableCell>
                  <TableCell align="center">
                    {totalFacilities}
                    {' '}
                    {totalFacilities > 1 ? 'Facilities' : 'Facility'}
                  </TableCell>
                  <TableCell align="right" style={{ fontWeight: 800 }}>
                    {currencySym}
                    {(!offerValid ? total : offerTotal).toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </ConditionallyVisible>
    </>
  );
};

PriceTable.propTypes = {
  billing: PropTypes.shape().isRequired,
  currencySym: PropTypes.string,
  updateBilling: PropTypes.func.isRequired,
  isPBAdmin: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  offerValid: PropTypes.bool,
  taxRate: PropTypes.number,
};
PriceTable.defaultProps = {
  currencySym: '£',
  isPBAdmin: false,
  offerValid: false,
  taxRate: 0.2,
};

export default PriceTable;
