import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Button, Dialog, DialogActions, DialogContent, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DescriptionIcon from '@material-ui/icons/Description';
import { invoiceNumberFormatted } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import InvoicePreview from './InvoicePreview';
import * as actions from '../../reducers/invoicesReducer';
import DownloadCSVButton from '../../components/DownloadCSVButton';
import GenerateInvoiceCSVData from './GenerateInvoiceCSVData';
import ResendInvoice from './ResendInvoice';
import InvoiceNoteSection from './InvoiceNoteSection';
import { DownloadInvoiceService } from '../../services/companiesServices';
import useToast from '../../hooks/useToast';

const PreviewInvoiceDialog = ({
  invoiceId,
  getInvoiceInformation,
  resetInvoiceInformationStore,
  updateInvoice,
  isInvoicePage,
  invoice: invoiceData,
}) => {
  const [open, setOpen] = useState(false);
  const invoice = isInvoicePage && invoiceData
    ? invoiceData
    : useSelector((state) => state.invoices.invoice);
  const isMobile = window.innerWidth < 768;
  const toast = useToast();

  const handlePreviewOpen = () => {
    if (!isInvoicePage && !invoiceData) {
      getInvoiceInformation(invoiceId);
    }
    setOpen(true);
  };

  const handleRequestClose = () => {
    resetInvoiceInformationStore();
    setOpen(false);
  };

  const invoiceItems = [].concat(
    invoice.formattedReservations,
  ).concat(invoice.formattedSubscriptions)
    .sort((a, b) => b.startTime - a.startTime);

  const fieldsAndData = !isInvoicePage
    ? GenerateInvoiceCSVData(invoiceItems) : { fields: [], data: [] };
  const csvFilename = invoiceItems.length > 0 ? `${invoiceNumberFormatted(invoice.invoiceNumber)}.csv` : '';

  const handleDownload = (invoiceToDownload) => {
    const { id, companyId, invoiceNumber } = invoiceToDownload;
    const filename = `${invoiceNumberFormatted(invoiceNumber)}.pdf`;

    DownloadInvoiceService(companyId, id).then((res) => {
      if (res.status === 201) {
        toast.trigger({ type: 'info', message: 'Invoice downloaded successfully!' });
        const blob = new Blob([Buffer.from(res.data.data)], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        setOpen(false);
      } else {
        toast.trigger({ type: 'error', message: 'Something went wrong, please try again later' });
      }
    });
  };

  return (
    <div>
      {/* Open dialog button */}
      <Button onClick={handlePreviewOpen}>
        <DescriptionIcon />
      </Button>

      <Dialog
        open={open}
        fullScreen={isMobile}
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        {/* Title */}
        {!isInvoicePage && (
          <DialogTitle id="form-dialog-title">
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {`Invoice ID: ${invoice.id}`}
            </Typography>
          </DialogTitle>
        )}

        <div style={{ display: 'flex', alignItems: 'center', padding: '1rem 2rem' }}>
          {/* Add notes */}
          {!isInvoicePage && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <InvoiceNoteSection
                updateInvoice={updateInvoice}
                invoiceId={invoice.id}
                existingNote={invoice.notes}
              />
            </div>
          )}

          {/* Close dialog */}
          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'end' }}>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={handleRequestClose} />
            </DialogActions>
          </div>
        </div>

        {!isInvoicePage && (
          <hr style={{ width: '95%', marginBottom: '1rem', opacity: '25%' }} />
        )}

        <DialogContent>
          {/* Preview  */}
          <InvoicePreview
            invoice={invoice}
            reservations={invoiceItems}
            showTypeSymbol
          />

          {/* Action buttons */}
          {!isInvoicePage && (
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '1rem 0rem',
          }}
          >
            <DownloadCSVButton fieldsAndData={fieldsAndData} filename={csvFilename} />
            <ConditionallyVisible condition={invoice.status === 'UNPAID'}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleDownload(invoice)}
              >
                Export PDF
              </Button>
              <ResendInvoice invoiceId={invoice.id} />
              <ResendInvoice invoiceId={invoice.id} toAdditionalUser />
            </ConditionallyVisible>
          </div>
          )}

        </DialogContent>
      </Dialog>
    </div>
  );
};

PreviewInvoiceDialog.propTypes = {
  getInvoiceInformation: PropTypes.func.isRequired,
  updateInvoice: PropTypes.func.isRequired,
  resetInvoiceInformationStore: PropTypes.func.isRequired,
  invoiceId: PropTypes.string.isRequired,
  invoice: PropTypes.shape(),
  isInvoicePage: PropTypes.bool,
};

PreviewInvoiceDialog.defaultProps = {
  isInvoicePage: false,
  invoice: null,
};

const mapDispatchToProps = (dispatch) => ({
  getInvoiceInformation: (id) => dispatch(actions.getInvoiceInformation(id)),
  resetInvoiceInformationStore: () => dispatch(actions.resetInvoiceInformationStore()),
  updateInvoice: (invoiceId, keyValue) => dispatch(actions.updateInvoice(invoiceId, keyValue)),
});

export default connect(null, mapDispatchToProps)(PreviewInvoiceDialog);
