import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/payments.css';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { Toolbar } from '@material-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import TableFormattedName from '../../components/TableFormattedName';
import AddTeamMemberDialog from './components/AddTeamMemberDialog';
import { EditTeamMemberDialog } from './components/EditTeamMemberDialog';
import { useCompany, usePitchbookingUser } from '../../hooks';
import { RemoveTeamMemberDialog } from './components/RemoveTeamMemberDialog';

const TeamMembersTable = ({
  team,
}) => {
  const queryClient = useQueryClient();
  const { isPitchbookingManager, id: userId } = usePitchbookingUser();
  const { id: companyId, products } = useCompany();

  const includeSites = team.some((t) => t.siteName !== null);
  const isSmsEnabled = products?.smsNotifications === 'ENABLED';

  return (
    <div className="table-section">
      <Paper
        style={{
          borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden', width: '100%',
        }}
        component={Paper}
      >
        <Toolbar style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
          <AddTeamMemberDialog />
        </Toolbar>

        <MaterialTable
          className="table"
          aria-label="simple table"
          data={team}
          title="Pitchbooking Team Members"
          icons={{
            NextPage: () => <ChevronRight />,
            PreviousPage: () => <ChevronLeft />,
            Search: () => <Search />,
            Export: () => <SaveAlt />,
            ResetSearch: () => <Clear />,
            SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
          }}
          columns={[
            {
              title: 'User',
              field: 'user',
              render: (teamMemberEntry) => (
                <TableFormattedName
                  user={teamMemberEntry}
                  currentUser={userId}
                  showEmail
                  showTags
                  showPhone={isSmsEnabled}
                />
              ),
            },
            {
              title: 'Receives emails',
              field: 'receivesEmails',
              render: (teamMemberEntry) => (
                teamMemberEntry.receivesEmails
                  ? (<div className="keys activeKey" />)
                  : (<div className="keys inactiveKey" />)
              ),
              customFilterAndSearch: (term, row) => {
                const string = `${row.firstName} ${row.lastName} ${row.teamName} ${row.email}`.toLowerCase();
                return string.includes(term.toLowerCase());
              },
            },
            ...(isSmsEnabled ? [{
              title: 'Receives SMS',
              field: 'receivesEmails',
              render: ({ userPreferences }) => (userPreferences?.sms ?? false
                ? <div className="keys activeKey" />
                : <div className="keys inactiveKey" />),
            }] : []),
            {
              title: 'Site',
              field: 'siteName',
              render: (teamMemberEntry) => (
                teamMemberEntry.siteName
                  ? teamMemberEntry.siteName
                  : 'All'
              ),
              hidden: !includeSites,
            },
            {
              title: 'Role',
              field: 'roleName',
              render: (teamMemberEntry) => (
                teamMemberEntry.roleName
                  ? teamMemberEntry.roleName
                  : ''
              ),
              hidden: !isPitchbookingManager,
            },
            {
              title: 'Job Title',
              field: 'jobRole',
              render: (teamMemberEntry) => (
                teamMemberEntry.jobRole
                  ? teamMemberEntry.jobRole
                  : ''
              ),
              hidden: !isPitchbookingManager,
            },
            {
              title: '',
              field: '',
              sorting: false,
              align: 'center',
              render: (teamMemberEntry) => (
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <RemoveTeamMemberDialog
                    name={`${teamMemberEntry.firstName} ${teamMemberEntry.lastName}`}
                    userCompanyId={teamMemberEntry.id}
                  />
                  <EditTeamMemberDialog
                    user={teamMemberEntry}
                    success={() => queryClient.invalidateQueries('company', companyId, 'users')}
                  />
                </div>
              ),
            },
          ]}
          options={{
            search: true,
            tableLayout: 'auto',
            emptyRowsWhenPaging: false,
            exportButton: true,
            exportFileName: 'Pitchbooking Team Members',
            pageSize: 25,
            pageSizeOptions: [10, 25, 50, 100],
            showFirstLastPageButtons: false,
            showTitle: false,
            headerStyle: {
              backgroundColor: '#efefef',
              fontWeight: 'bold',
            },
          }}
          localization={{ toolbar: { exportTitle: 'Download' } }}
        />
      </Paper>
    </div>
  );
};

TeamMembersTable.propTypes = {
  team: PropTypes.shape().isRequired,
};

export default TeamMembersTable;
