import moment from 'moment';
import { invoiceNumberFormatted } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import { DownloadInvoiceService } from '../../services/companiesServices';

export const handleDownload = (invoiceToDownload, onDownloadFinish) => {
  const { id, companyId, invoiceNumber } = invoiceToDownload;
  const filename = `${invoiceNumberFormatted(invoiceNumber)}.pdf`;

  DownloadInvoiceService(companyId, id)
    .then((res) => {
      if (res.status === 201) {
        const blob = new Blob([Buffer.from(res.data.data)], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        if (onDownloadFinish) onDownloadFinish(true);
      } else if (onDownloadFinish) {
        onDownloadFinish(false);
      }
    });
};

export const getInvoiceLabelStatus = (status, dueDate, timezone) => {
  const formattedDate = moment(dueDate)?.tz(timezone)?.format('DD MMM YYYY');
  switch (status) {
    case 'PAID': return 'Paid';
    case 'UNPAID': return `Unpaid. Due: ${formattedDate}`;
    case 'PENDING': return `Pending... Due: ${formattedDate}`;
    case 'VOID': return 'Voided';
    default: return null;
  }
};
