/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Moment from 'moment-timezone';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import Back from '../../../../components/Back';
import MultipleCancelRefundDialog from '../MultipleCancelRefundDialog';
import RecordPaymentDialog from '../RecordPaymentDialog';
import * as reservationActions from '../../../../reducers/reservationsReducer';
import { updateFacilityBooking, getBooking, resetSelectedRows } from '../../../../reducers/bookingsReducer';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';
import * as actions from '../../../../reducers/bookingsReducer';
import '../../../../styles/payments.css';
import '../../../../styles/bookingView.css';
import styles from './styles.module.css';
import AddEditBookingNotesDialog from '../AddEditBookingNotesDialog';
import FacilityUpdateDialog from '../../../../components/FacilityUpdateDialog';
import ViewAttendeeDialog from '../../../events/eventsView/ViewAttendeeDialog';
import { UpdatePriceModal } from './UpdatePriceModal';
import { PartialPaymentsModal } from './PartialPaymentsModal';
import { omitFormProperties } from '../../../../utils';

function DetailPaneTitle(props) {
  const { title } = props;
  return (
    <Typography style={{ padding: '20px 16px 0', color: '#6699FF' }} variant="h6">{title}</Typography>
  );
}

const BookingView = ({
  getBooking,
  match,
  reservation,
  reservationLoading,
  companies,
  selectedRows,
  resetSelectedRows,
  cancelandRefundReservations,
  recordMultipleReservationPayment,
  recordMultipleReservationPaymentFailure,
  cancelReservations,
  requestUpdateFacilityBooking,
  toggleEditBookingDialog,
}) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isEditPriceOpen, setIsEditPriceOpen] = useState(false);
  const [isViewPaymentsOpen, setIsViewPaymentsOpen] = useState(false);
  const { facility } = reservation;
  const { reservationId } = match.params;

  useEffect(() => {
    getBooking(reservationId);
  }, [getBooking, match.params]);

  useEffect(() => {
    resetSelectedRows();
  }, []);

  // If the reservation is loading, show a loading message
  if (!reservation?.id && reservationLoading) {
    return (
      <div style={{ padding: 20 }}>
        <h3>Loading</h3>
      </div>
    );
  }

  const handleEditClick = () => {
    setIsEditOpen(true);
    toggleEditBookingDialog();
  };

  const handleEditClose = () => {
    setIsEditOpen(false);
    toggleEditBookingDialog();
  };

  const requestMultipleCancel = () => {
    const singularBooking = true;
    let bookingArray = [reservation];
    if (bookingArray[0].type !== 'SUBSCRIPTION') {
      bookingArray = bookingArray.map((row) => row.id);
    }
    cancelandRefundReservations(bookingArray, cancelReservations, singularBooking);
  };

  const requestMultipleRecordPayment = (singularBooking) => {
    const bookingArray = [reservation];

    const paidOrInvoicePendingSelected = (
      bookingArray.some((row) => row.paid === true || row.paid === null)
    ) || (bookingArray.some((row) => row.invoicePending === true));
    let multipleUsersSelected = false;
    const userToCheck = bookingArray[0].userId;

    for (const reservations of bookingArray) {
      if (reservations.userId !== userToCheck) {
        multipleUsersSelected = true;
      }
    }

    if (!multipleUsersSelected && !paidOrInvoicePendingSelected) {
      recordMultipleReservationPayment(bookingArray, singularBooking);
    } else if (multipleUsersSelected) {
      recordMultipleReservationPaymentFailure('You can only record payment for one user at a time.');
    } else if (paidOrInvoicePendingSelected) {
      recordMultipleReservationPaymentFailure('You can only record payment for unpaid bookings or bookings that haven\'t been invoiced.');
    }
  };

  const {
    companyInfo: { stripeId, currencySym },
  } = companies;

  const {
    paymentStatus,
    paymentStatusText,
    facility: { timezone },
    startTime,
    endTime,
    successfulOrder,
    facilityName,
    allocations,
    amenityNames,
    ageGroupBookingModifier,
    eventBookingModifier,
    status,
    addonNames,
    payLaterDate,
  } = reservation;

  const datePaymentMade = successfulOrder ? successfulOrder.paidAt : null;
  const stripePaymentId = successfulOrder ? successfulOrder.paymentId : null;
  const receivedByUser = successfulOrder ? successfulOrder.createdByUser : null;
  const allocationNotes = allocations.reduce((acc, allocation) => {
    if (allocation.notes !== null) {
      return acc.concat(`${allocation.notes} `);
    }
    return acc;
  }, '');
  const createdByUserFullname = allocations[0] ? `${allocations[0].createdByUser?.firstName} ${allocations[0].createdByUser?.lastName}` : '';

  const bookingArray = selectedRows;
  const allocationFormIndex = allocations.findIndex((allocation) => allocation.formData);
  const keycodes = reservation.allocations.filter((x) => !!x.keycodes).map((x) => `${x.facility.name}: ${x.keycodes?.keycodes.map((y) => y.keycode).join(',')}`);

  return (
    <div style={{ margin: '1rem' }}>
      <Back />

      <div style={{ marginTop: '1rem' }}>
        <Card>
          <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {/* Booking */}
            <section className={styles.section}>
              <Typography variant="h6" color="secondary">Booking</Typography>

              <div className={styles.grid}>
                <ListItemText
                  primary={facilityName}
                  secondary="Facility"
                  style={{ flexBasis: '15rem' }}
                />
                <ListItemText
                  primary={Moment(startTime).tz(timezone).format('LL')}
                  secondary="Date"
                  style={{ flexBasis: '15rem' }}
                />
                <ListItemText
                  primary={`${Moment(startTime).tz(timezone).format('LT')} - ${Moment(endTime).tz(timezone).add(1, 's').format('LT')}`}
                  secondary="Timeslot"
                  style={{ flexBasis: '15rem' }}
                />
                <ListItemText
                  primary={reservation.sport ? reservation.sport : 'N/A'}
                  secondary="Sport"
                  style={{ flexBasis: '15rem' }}
                />
                <ListItemText
                  primary={status}
                  secondary="Status"
                  style={{ flexBasis: '15rem' }}
                />
                <ListItemText
                  primary={Moment(reservation.createdAt).tz(timezone).format('LLL')}
                  secondary="Created At"
                  style={{ flexBasis: '15rem' }}
                />
                <ListItemText
                  primary={createdByUserFullname}
                  secondary="Created By"
                />
                <ConditionallyVisible
                  condition={ageGroupBookingModifier !== null && ageGroupBookingModifier.name}
                >
                  <ListItemText
                    primary={ageGroupBookingModifier !== null ? ageGroupBookingModifier.name : 'N/A'}
                    secondary="Age Group"
                  />
                </ConditionallyVisible>
                <ConditionallyVisible
                  condition={eventBookingModifier !== null && eventBookingModifier.name}
                >
                  <ListItemText
                    primary={eventBookingModifier !== null ? eventBookingModifier.name : 'N/A'}
                    secondary="Event"
                  />
                </ConditionallyVisible>
                <ConditionallyVisible
                  condition={keycodes.length > 0}
                >
                  <ListItemText
                    primary={keycodes.join(',')}
                    secondary="Keycodes"
                  />
                </ConditionallyVisible>
                <ConditionallyVisible condition={allocationNotes.length > 0}>
                  <ListItemText
                    style={{ gridColumn: '1 / -1', marginTop: '1rem' }}
                    primary={allocationNotes}
                    secondary="Additional Information"
                  />
                </ConditionallyVisible>
                <ConditionallyVisible condition={reservation.notes}>
                  <ListItemText
                    style={{ gridColumn: '1 / -1', marginTop: '1rem' }}
                    primary={reservation.notes}
                    secondary="Admin Notes"
                  />
                </ConditionallyVisible>
              </div>
            </section>

            {/* Addons/Amenities */}
            <ConditionallyVisible condition={
              (amenityNames && amenityNames.length > 0) || (addonNames && addonNames.length > 0)
              }
            >
              <section className={styles.section}>
                <Typography variant="h6" color="secondary">Addons/Amenities</Typography>

                <div className={styles.grid}>
                  <ConditionallyVisible condition={addonNames && addonNames.length > 0}>
                    <div>
                      <ListItemText
                        primary={addonNames.join(', ')}
                        secondary="Addons"
                      />
                    </div>
                  </ConditionallyVisible>
                  <ConditionallyVisible condition={amenityNames && amenityNames.length > 0}>
                    <ListItemText
                      primary={amenityNames.join(', ')}
                      secondary="Amenities"
                    />
                  </ConditionallyVisible>
                </div>
              </section>
            </ConditionallyVisible>

            {/* Booker */}
            <section className={styles.section}>
              <Typography variant="h6" color="secondary">Booker</Typography>

              <div className={styles.grid}>
                <Link
                  href={`/users/${reservation.user.id}`}
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={`/users/${reservation.user.id}`}
                >
                  <ListItemText
                    primary={reservation.fullName}
                    secondary="Name"
                  />
                </Link>
                <ListItemText
                  primary={reservation.user.teamName || 'N/A'}
                  secondary="Team Name"
                />
                <ListItemText
                  primary={reservation.user.email}
                  secondary="Email Address"
                />
                <ListItemText
                  primary={reservation.user.phone ? `+${reservation.user.dialCode}${reservation.user.phone}` : 'N/A'}
                  secondary="Mobile Number"
                />

              </div>
            </section>

            {/* Payment */}
            <section className={styles.section}>
              <Typography variant="h6" color="secondary">Payment</Typography>

              <div className={styles.grid}>
                <ListItemText
                  primary={`${currencySym}${reservation.total.toFixed(2)} (${currencySym}${reservation.totalExTax.toFixed(2)} + ${currencySym}${reservation.tax.toFixed(2)} tax)`}
                  secondary="Price"
                />
                <ListItemText
                  primary={`${paymentStatusText} ${status !== 'PENDING' ? '' : ' (Processing Payment)'}`}
                  secondary="Payment Status"
                />
                {reservation.totalPaid && (
                  <ListItemText
                    primary={`Paid: ${currencySym}${reservation.totalPaid.toFixed(2)}`}
                    secondary={`Remaining: ${currencySym}${(reservation.total - reservation.totalPaid).toFixed(2)}`}
                  />
                )}

                <ConditionallyVisible condition={datePaymentMade}>
                  <ListItemText
                    primary={`${Moment(datePaymentMade).format('LLL')}`}
                    secondary="Payment Taken"
                  />
                </ConditionallyVisible>
                <ConditionallyVisible condition={payLaterDate}>
                  <ListItemText
                    primary={`${Moment(payLaterDate).format('LLL')}`}
                    secondary="Payment Deffered Until"
                  />
                </ConditionallyVisible>

                <ConditionallyVisible condition={receivedByUser}>
                  <ListItemText
                    primary={`${receivedByUser?.firstName} ${receivedByUser?.lastName}`}
                    secondary="Recorded Payment"
                  />
                </ConditionallyVisible>
              </div>
            </section>

            {/* Facility */}
            <section className={styles.section}>
              <Typography variant="h6" color="secondary">Facility</Typography>
              <div className={styles.grid}>
                <ListItemText
                  primary={facility.name}
                  secondary="Name"
                />

                <ListItemText
                  primary={reservation.cancellationReason
                    ? reservation.cancellationReason
                    : 'N/A'}
                  secondary="Cancellation Resaon"
                />

                <ListItemText
                  primary={reservation.cancellationDate
                    ? Moment(reservation.cancellationDate).tz(timezone).format('LLL')
                    : 'N/A'}
                  secondary="Cancellation Date"
                />
                <ListItemText
                  primary={reservation.cancelledByUser
                    ? `${reservation.cancelledByUser?.firstName} ${reservation.cancelledByUser?.lastName}`
                    : 'N/A'}
                  secondary="Cancelled By Name"
                />
              </div>
            </section>

            {/* Buttons */}
            <section className={styles.flexFlexible} style={{ justifyContent: 'space-between', gap: '1rem' }}>
              <div className={styles.flexFlexible} style={{ gap: '1rem' }}>
                <AddEditBookingNotesDialog
                  reservation={reservation}
                  onSuccess={() => getBooking(reservation.id)}
                />
                {allocationFormIndex >= 0
                  && (
                  <ViewAttendeeDialog
                    formData={omitFormProperties(
                      reservation.allocations[allocationFormIndex].formData,
                    )}
                  />
                  )}

                <>
                  <Button variant="contained" color="secondary" onClick={() => handleEditClick()}>Edit Booking</Button>
                  <FacilityUpdateDialog
                    allocation={isEditOpen ? reservation : null}
                    onClose={() => handleEditClose()}
                    onChange={async (facility, timeslots) => {
                      requestUpdateFacilityBooking(
                        reservation.id,
                        timeslots.map((x) => ({
                          ...x,
                          endTime: Moment(x.endTime),
                        })),
                      );
                    }}
                  />
                </>
                <ConditionallyVisible condition={status !== 'CANCELLED' && status !== 'PENDING'}>
                  <MultipleCancelRefundDialog
                    variant="contained"
                    buttonTitle="Cancel / Refund Bookings"
                    dialogTitle={`Cancel / Refund ${bookingArray.length} Booking${bookingArray.length > 1 ? 's' : ''}`}
                    multiple={bookingArray.length > 1}
                    selectedRows={bookingArray}
                    singularBooking
                    requestCancel={() => requestMultipleCancel()}
                    resetSelectedRows={() => resetSelectedRows()}
                  />
                </ConditionallyVisible>
                <ConditionallyVisible condition={(paymentStatus === 'UNPAID' || paymentStatus === 'PARTIAL') && status !== 'PENDING'}>
                  <>
                    <RecordPaymentDialog
                      variant="contained"
                      style={{
                        backgroundColor: '#ac372f',
                        color: '#fff',
                        width: '100%',
                      }}
                      buttonTitle="Record Payment"
                      dialogTitle={`Record payment for ${bookingArray.length} Booking${bookingArray.length > 1 ? 's' : ''}`}
                      requestMultipleRecordPayment={
                        (singularBooking) => requestMultipleRecordPayment(
                          singularBooking,
                        )
                    }
                      resetSelectedRows={() => resetSelectedRows()}
                      singularBooking
                      selectedReservations={[reservation]}
                    />
                    {!reservation.paid && reservation.partialPaymentOrders.length <= 0 && !reservation.invoices.some((x) => x.status !== 'VOID') && (
                      <>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => setIsEditPriceOpen(true)}
                        >
                          Update Price
                        </Button>
                        <UpdatePriceModal
                          reservation={reservation}
                          isOpen={isEditPriceOpen}
                          onClose={() => setIsEditPriceOpen(false)}
                          onSuccess={() => {
                            getBooking(reservationId);
                          }}
                        />
                      </>
                    )}
                  </>
                </ConditionallyVisible>
                <ConditionallyVisible condition={paymentStatus === 'PARTIAL'}>
                  <>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setIsViewPaymentsOpen(true)}
                    >
                      View Payments
                    </Button>
                    <PartialPaymentsModal
                      reservation={reservation}
                      isOpen={isViewPaymentsOpen}
                      onClose={() => setIsViewPaymentsOpen(false)}
                    />
                  </>
                </ConditionallyVisible>
              </div>
              <ConditionallyVisible condition={stripePaymentId}>
                <a
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://dashboard.stripe.com/${stripeId}/${stripePaymentId?.includes('sub_') ? 'subscriptions' : 'payments'}/${stripePaymentId}`}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ width: '100%' }}
                  >
                    View Payment in Stripe
                  </Button>
                </a>
              </ConditionallyVisible>
            </section>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

BookingView.propTypes = {
  getBooking: PropTypes.func.isRequired,
  toggleEditBookingDialog: PropTypes.func.isRequired,
  resetSelectedRows: PropTypes.func.isRequired,
  recordMultipleReservationPaymentFailure: PropTypes.func.isRequired,
  recordMultipleReservationPayment: PropTypes.func.isRequired,
  requestUpdateFacilityBooking: PropTypes.func.isRequired,
  cancelandRefundReservations: PropTypes.func.isRequired,
  cancelReservations: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  reservation: PropTypes.shape().isRequired,
  companies: PropTypes.shape().isRequired,
  selectedRows: PropTypes.shape().isRequired,
  reservationLoading: PropTypes.bool.isRequired,
};
DetailPaneTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {
    bookings,
    companies,
  } = state;
  const { cancelReservations } = state.reservation;

  const { reservation, reservationLoading, selectedRows } = bookings;
  return {
    reservation,
    reservationLoading,
    companies,
    cancelReservations,
    selectedRows,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getBooking: (id) => dispatch(getBooking(id)),
  requestFacilitiesRetrieval: () => dispatch(facilitiesActions.requestFacilitiesRetrieval()),
  cancelandRefundReservations: (reservations, refund, sendEmail, singularBooking) => dispatch(
    reservationActions.cancelReservations(reservations, refund, sendEmail, singularBooking),
  ),
  resetSelectedRows: () => dispatch(
    resetSelectedRows(),
  ),
  toggleEditBookingDialog: () => dispatch(actions.toggleEditBookingDialog()),
  recordMultipleReservationPayment: (reservations, singularBooking) => dispatch(
    reservationActions.recordMultipleReservationPayment(reservations, singularBooking),
  ),
  recordMultipleReservationPaymentFailure: (error) => dispatch(
    reservationActions.recordMultipleReservationPaymentFailure(error),
  ),
  requestUpdateFacilityBooking: (
    bookingId,
    timeslots,
  ) => dispatch(updateFacilityBooking(bookingId, timeslots, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingView);
