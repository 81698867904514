import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import CloseDialogIcon from '../shared-components/CloseDialogIcon';

const WarningMessage = (props) => {
  const { message, triggerClose, warningMessageForAdminsPersist } = props;

  const convertTextUrlsToLinks = (text) => {
    const regex = /(https?:\/\/[^\s]+)/g;
    return text.split(regex).map((part) => {
      if (part.match(regex)) {
        const url = part.endsWith('.') ? part.slice(0, -1) : part;
        return <a href={url} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>Link</a>;
      }
      return part;
    });
  };

  return (
    <div>
      <Alert
        severity={message?.type?.toLowerCase()}
        variant="filled"
        style={{ padding: '10px', margin: 0, fontWeight: 'bold' }}
        action={
        !warningMessageForAdminsPersist ? (
          <CloseDialogIcon
            onClick={() => triggerClose()}
            color="white"
          />
        ) : null
      }
      >
        {message.value && message.value.length > 0 && (
          convertTextUrlsToLinks(message?.value)
        )}
      </Alert>
    </div>
  );
};

WarningMessage.propTypes = {
  message: PropTypes.string.isRequired,
  triggerClose: PropTypes.func.isRequired,
  warningMessageForAdminsPersist: PropTypes.bool.isRequired,
};

export default (WarningMessage);
