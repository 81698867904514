import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import FilterList from '@material-ui/icons/FilterList';
import ViewColumn from '@material-ui/icons/ViewColumn';

import TableContainer from '@material-ui/core/TableContainer';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import moment from 'moment';
import {
  Typography, Link, Chip, Avatar, Tooltip,
} from '@material-ui/core';
import BusinessTwoToneIcon from '@material-ui/icons/BusinessTwoTone';
import { getCompanies } from '../../services/companiesServices';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';
import { companyStatuses, accountManagerColors, billingMethodColors } from './constants';
import { useOrigin } from '../../hooks';
import { categoryOptions, regionOptions } from './helper';

export const determineRecurrenceText = (xeroInvoice) => {
  let recurrenceText = '';
  if (xeroInvoice?.schedule?.unit === 'MONTHLY') {
    switch (xeroInvoice?.schedule?.period) {
      case 1:
        recurrenceText = 'Every month';
        break;
      case 3:
        recurrenceText = 'Every quarter';
        break;
      case 6:
        recurrenceText = 'Every six months';
        break;
      case 12:
        recurrenceText = 'Every year';
        break;
      case 24:
        recurrenceText = 'Every two years';
        break;
      default:
        recurrenceText = `Every ${xeroInvoice?.schedule?.period} months`;
    }
  } else {
    recurrenceText = 'N/A';
  }
  return recurrenceText;
};

const getLabelFromValue = (options, value) => {
  const option = options.find((opt) => opt.value === value);
  return option ? option.label : 'N/A';
};

export default function Companies() {
  const [companies, setCompanies] = useState([]);
  const [status, setStatus] = useState('ACTIVE');

  const fetchCompanies = async () => {
    try {
      const res = await getCompanies({ status });
      setCompanies(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [status]);

  const handleBillingMethod = (billingMethod) => (
    <Chip
      label={(
        <Typography variant="body2" style={{ fontWeight: '600' }}>
          {billingMethod}
        </Typography>
      )}
      style={{
        border: `2px solid ${billingMethodColors[billingMethod.toLowerCase()]?.border}`,
        backgroundColor: billingMethodColors[billingMethod.toLowerCase()]?.backgroundColor,
      }}
    />
  );

  const handleAccountOwner = (row) => {
    const accountOwner = row.accountOwnerDetails
      ? row.accountOwnerDetails.firstName : 'Unassigned';
    const accountOwnerColor = accountManagerColors[accountOwner.toLowerCase()]
      || accountManagerColors.unassigned;
    return (
      <Chip
        avatar={(
          <Avatar style={{ backgroundColor: `${accountOwnerColor.border}`, display: 'flex', justifyContent: 'space-around' }}>
            <Typography variant="body2" style={{ fontWeight: '900', color: 'white' }}>
              {accountOwner.slice(0, 1)}
            </Typography>
          </Avatar>
        )}
        label={(
          <Typography variant="body2" style={{ fontWeight: '600' }}>
            {accountOwner}
          </Typography>
        )}
        style={{ border: `2px solid ${accountOwnerColor.border}`, backgroundColor: accountOwnerColor.backgroundColor }}
      />
    );
  };

  const { bookerUrl } = useOrigin();

  return (
    <PitchbookingUserOnly>
      <div style={{
        display: 'flex', flexDirection: 'column', gap: '2rem', padding: '2rem 3rem',
      }}
      >
        <Typography variant="h5" style={{ fontWeight: '600', textDecoration: 'underline' }}>
          Company Overview
        </Typography>

        <div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
          <PBInput
            id="status"
            type="select"
            label="Status"
            options={companyStatuses}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          />

          <div>
            <PBInput
              id="companyCount"
              type="text"
              label="Total Companies"
              value={companies.length}
              disabled
              icon={<BusinessTwoToneIcon style={{ fill: 'var(--primary)', marginRight: '0.5rem' }} />}
            />
          </div>
        </div>

        <TableContainer
          style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden' }}
          component={Paper}
        >
          <MaterialTable
            data={companies}
            title="Pitchbooking Companies"
            icons={{
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              Export: () => <SaveAlt />,
              ResetSearch: () => <Clear />,
              SortArrow: () => <UnfoldMoreIcon style={{ fill: 'var(--accent)' }} />,
              Filter: () => <FilterList />,
              ViewColumn: () => <ViewColumn />,
            }}
            columns={[
              {
                title: 'Name',
                filtering: false,
                sorting: true,
                customFilterAndSearch: (term, rowData) => (
                  rowData.name.toLowerCase().includes(term.toLowerCase())
                ),
                customSort: (a, b) => a.name.localeCompare(b.name),
                render: (row) => (
                  <Typography variant="body2" style={{ fontWeight: '600' }}>
                    <Link
                      href={`${bookerUrl}/partners/${row.code}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="secondary"
                      underline="always"
                    >
                      {row.name}
                    </Link>
                  </Typography>
                ),
              },
              {
                title: 'Billing',
                filtering: false,
                sorting: true,
                customFilterAndSearch: (term, rowData) => (
                  rowData.accounting?.xeroInfo?.repeatingInvoices.some(
                    (i) => determineRecurrenceText(i).toLowerCase().includes(term.toLowerCase()),
                  )
                ),
                render: (row) => (
                  row.accounting?.xeroInfo?.repeatingInvoices?.map((invoice) => (
                    <Typography variant="body2" style={{ fontWeight: '600' }}>
                      {determineRecurrenceText(invoice)}
                    </Typography>
                  ))
                ),
              },

              {
                title: 'Billing Method',
                filtering: false,
                sorting: true,
                customFilterAndSearch: (term, rowData) => (
                  (rowData.accounting?.billingMethod || 'N/A').toLowerCase().includes(term.toLowerCase())
                ),
                customSort: (a, b) => (a.accounting?.billingMethod || '').localeCompare(b.accounting?.billingMethod || ''),
                render: (row) => (
                  <>
                    {handleBillingMethod(row.accounting?.billingMethod || 'N/A')}
                  </>
                ),
              },
              {
                title: 'Account Owner',
                filtering: false,
                sorting: true,
                customFilterAndSearch: (term, rowData) => (
                  rowData.accountOwnerDetails?.firstName.toLowerCase().includes(term.toLowerCase())
                ),
                customSort: (a, b) => a.accountOwnerDetails?.firstName.localeCompare(
                  b.accountOwnerDetails?.firstName,
                ),
                render: (row) => (
                  <>
                    {handleAccountOwner(row)}

                  </>
                ),
              },
              {
                title: 'Billing Date',
                filtering: false,
                sorting: true,
                customFilterAndSearch: (term, rowData) => (
                  rowData.accounting?.nextStripePaymentDate
                  && moment(rowData.accounting.nextStripePaymentDate).format('ddd, DD-MMM-YYYY').toLowerCase().includes(term.toLowerCase())
                ),
                render: (row) => (
                  <>
                    <div>
                      {row.accounting?.pausedUntil ? (
                        <Typography variant="body2" style={{ fontWeight: '600' }}>
                          {`Stripe Payment paused until: ${moment(row.accounting.pausedUntil).format('ddd, DD-MMM-YYYY')} (${moment(row.accounting.pausedUntil).fromNow()})`}
                        </Typography>
                      ) : (
                        row.accounting?.nextStripePaymentDate && (
                        <Typography variant="body2" style={{ fontWeight: '600' }}>
                          {`Next Stripe Payment Date: ${moment(row.accounting.nextStripePaymentDate).format('ddd, DD-MMM-YYYY')} (${moment(row.accounting.nextStripePaymentDate).fromNow()})`}
                        </Typography>
                        )
                      )}
                    </div>
                    <div>
                      {row.accounting?.xeroInfo?.repeatingInvoices?.length > 0 && (
                        row.accounting.xeroInfo.repeatingInvoices.map((invoice) => (
                          invoice?.schedule?.nextScheduledDate && (
                          <Typography variant="body2" style={{ fontWeight: '600' }} key={invoice.schedule.nextScheduledDate}>
                            {`Next Xero Repeating Invoice Date: ${moment(invoice.schedule.nextScheduledDate).format('ddd, DD-MMM-YYYY')} 
                            (${moment(invoice.schedule.nextScheduledDate).fromNow()})`}
                          </Typography>
                          )
                        ))
                      )}
                    </div>
                    <div>
                      {row.accounting?.contractRenewalDate && (
                        <Tooltip title={row.accounting.contractDetails || ''}>
                          <Typography variant="body2" style={{ fontWeight: '600' }}>
                            {`Contract Renewal Date: ${moment(row.accounting.contractRenewalDate).format('DD-MMM-YYYY')} 
                               (${moment(row.accounting.contractRenewalDate).fromNow()})`}
                          </Typography>
                        </Tooltip>
                      )}
                    </div>
                  </>
                ),
              },
              {
                title: 'Created at',
                filtering: false,
                sorting: true,
                customFilterAndSearch: (term, rowData) => (
                  moment(rowData.createdAt).format('ddd, DD MMM YYYY').toLowerCase().includes(term.toLowerCase())
                ),
                customSort: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
                render: (row) => (
                  <Typography variant="body2" style={{ fontWeight: '600' }}>
                    {moment(row.createdAt).format('ddd, DD MMM YYYY')}
                  </Typography>
                ),
              },
              {
                title: 'Go Live Date',
                filtering: false,
                sorting: true,
                customFilterAndSearch: (term, rowData) => (
                  rowData.onboarding?.goLiveDate
                  && moment(rowData.onboarding.goLiveDate).format('ddd, DD MMM YYYY').toLowerCase().includes(term.toLowerCase())
                ),
                customSort: (a, b) => moment(a.onboarding?.goLiveDate)
                  .diff(moment(b.onboarding?.goLiveDate)),
                render: (row) => (
                  row.onboarding?.goLiveDate ? (
                    <Typography variant="body2" style={{ fontWeight: '600' }}>
                      {moment(row.onboarding.goLiveDate).format('ddd, DD MMM YYYY')}
                    </Typography>
                  ) : <>N/A</>
                ),
              },
              {
                title: 'Region',
                filtering: false,
                sorting: true,
                customFilterAndSearch: (term, rowData) => (
                  getLabelFromValue(
                    regionOptions, rowData.accounting?.region,
                  ).toLowerCase().includes(term.toLowerCase())
                ),
                customSort: (a, b) => getLabelFromValue(
                  regionOptions, a.accounting?.region,
                ).localeCompare(getLabelFromValue(regionOptions, b.accounting?.region)),
                render: (row) => (
                  <Typography variant="body2" style={{ fontWeight: '600' }}>
                    {getLabelFromValue(regionOptions, row.accounting?.region)}
                  </Typography>
                ),
              },
              {
                title: 'Category',
                filtering: false,
                sorting: true,
                customFilterAndSearch: (term, rowData) => (
                  getLabelFromValue(
                    categoryOptions, rowData.accounting?.category,
                  ).toLowerCase().includes(term.toLowerCase())
                ),
                customSort: (a, b) => getLabelFromValue(
                  categoryOptions, a.accounting?.category,
                ).localeCompare(getLabelFromValue(categoryOptions, b.accounting?.category)),
                render: (row) => (
                  <Typography variant="body2" style={{ fontWeight: '600' }}>
                    {getLabelFromValue(categoryOptions, row.accounting?.category)}
                  </Typography>
                ),
              },
            ]}
            options={{
              search: true,
              filtering: true,
              selection: false,
              emptyRowsWhenPaging: false,
              tableLayout: 'auto',
              exportButton: false,
              exportFileName: 'Pitchbooking Companies',
              pageSize: 50,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: false,
              showTitle: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: 'bold',
              },
              columnsButton: true,
            }}
            localization={{
              toolbar: {
                exportTitle: 'Download',
              },
            }}
          />
        </TableContainer>
      </div>
    </PitchbookingUserOnly>
  );
}
