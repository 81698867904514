// @flow weak

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { invoiceNumberFormatted } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import Terminal from '../../components/Stripe/Terminal';
import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import * as actions from '../../reducers/invoicesReducer';
import ToggleButtons from '../../components/ToggleButtons';

export const paymentMethodButtonsData = (
  includeNotCharged = false, includeEPOS = false, includeTerminal = false,
  additionalPaymentMethods = [], includeCardNotPresent = false, isAnonymousPayment = false,
) => {
  const paymentMethods = [{
    buttonTitle: 'Bank Transfer',
    buttonValue: 'BANKTRANSFER',
  },
  ];

  if (includeNotCharged) {
    paymentMethods.push({
      buttonTitle: 'Not Charged',
      buttonValue: 'NOT_CHARGED',
    });
  }

  if (includeEPOS) {
    paymentMethods.push({
      buttonTitle: 'Till',
      buttonValue: 'EXT_POS',
    });
  }
  if (includeTerminal) {
    paymentMethods.push({
      buttonTitle: 'Terminal',
      buttonValue: 'TERMINAL',
    });
  }
  paymentMethods.push({
    buttonTitle: 'Cash',
    buttonValue: 'CASH',
  });
  paymentMethods.push({
    buttonTitle: 'Card',
    buttonValue: 'CARD',
  });
  if (includeCardNotPresent && !isAnonymousPayment) {
    paymentMethods.push({
      buttonTitle: 'Card Not Present',
      buttonValue: 'CARD_NOT_PRESENT',
    });
  }

  if (additionalPaymentMethods?.length > 0) {
    additionalPaymentMethods?.forEach((paymentMethod) => {
      paymentMethods.push({
        buttonTitle: paymentMethod,
        buttonValue: paymentMethod,
      });
    });
  }

  return paymentMethods;
};
class InvoiceRecordPaymentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      buttonDisabled: false,
    };
  }

  handleRequestClose = () => {
    const { updateRecordPaymentInvoiceStore } = this.props;
    updateRecordPaymentInvoiceStore({
      paymentMethod: 'BANKTRANSFER',
    });
    this.setState({ open: false });
  };

  performAction = (invoiceId) => {
    this.setState({ buttonDisabled: true });
    const { requestRecordPaymentInvoice, getCompanyInvoices } = this.props;
    requestRecordPaymentInvoice(invoiceId);
    getCompanyInvoices('UNPAID');
    this.setState({ open: false });
  }

  handlePaymentMethodToggle = (option) => {
    const { updateRecordPaymentInvoiceStore } = this.props;
    updateRecordPaymentInvoiceStore({
      paymentMethod: option,
    });
  };

  handlePaidAt = (date) => {
    const { updateRecordPaymentInvoiceStore } = this.props;
    updateRecordPaymentInvoiceStore({
      paidAt: date.format(),
    });
  };

  render() {
    const isMobile = window.innerWidth < 768;
    const {
      invoice, recordPaymentInvoice, company,
    } = this.props;
    const { invoiceNumber, id } = invoice;
    const { isPowerleague, products } = company;
    const { paymentMethod, paidAt } = recordPaymentInvoice;
    const { buttonDisabled, open } = this.state;
    // const { reservationsPaymentCreationSuccess, reservationsCreationError } = invoice;
    const dialogueActionSuccess = null;

    return (
      <div>
        <Button
          id="manager-bookings-record-payment"
          variant="contained"
          style={{
            width: 175,
            backgroundColor: '#6699FF',
            color: '#fff',
          }}
          onClick={() => this.setState({ open: true })}
        >
          Record Payment
        </Button>
        <Dialog open={open} fullScreen={isMobile} maxWidth="md">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>{`Record payment for invoice ${invoiceNumberFormatted(invoiceNumber)}`}</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <ConditionallyVisible condition={dialogueActionSuccess === null}>
            <DialogContent>
              <DialogContentText>
                Select payment method:
              </DialogContentText>
              <ToggleButtons
                buttonsData={paymentMethodButtonsData(false, isPowerleague, products?.terminal === 'ENABLED')}
                changeOption={(option) => this.handlePaymentMethodToggle(option)}
                value={paymentMethod}
              />
              <ConditionallyVisible condition={paymentMethod !== 'TERMINAL'}>
                <br />
                <DialogContentText>
                  Select payment date:
                </DialogContentText>
                <DayPickerSingleDateController
                  onDateChange={(date) => this.handlePaidAt(date)}
                  date={paidAt !== null ? moment(paidAt) : null}
                  noBorder
                />
              </ConditionallyVisible>
              <ConditionallyVisible condition={paymentMethod === 'TERMINAL'}>
                <Terminal
                  companyStripeId={company.stripeId}
                  transactionType="INVOICE"
                  transactionId={id}
                  userId={invoice.userId}
                  handleRequestClose={() => this.handleRequestClose()}
                  companyId={company.id}
                />
              </ConditionallyVisible>
            </DialogContent>
            <DialogActions>
              {paymentMethod !== 'TERMINAL' && (
                <>
                  <Button variant="outlined" onClick={() => this.handleRequestClose()} color="secondary">
                    Go back
                  </Button>
                  <Button disabled={buttonDisabled} variant="contained" onClick={() => this.performAction(id)} color="primary">
                    Record payment
                  </Button>
                </>
              )}
            </DialogActions>
          </ConditionallyVisible>
          {/* <ConditionallyVisible condition={reservationsPaymentCreationSuccess === false}>
            <DialogContent>
              <DialogContentText>
                <div style={{ color: '#ac372f', padding: '45px' }}>
                Error! There was a problem recording payment for these reservations.</div>
                <ConditionallyVisible condition={reservationsCreationError !== null}>
                  <div style={{ color: '#ac372f', padding: '45px' }}>
                  {reservationsCreationError}</div>
                </ConditionallyVisible>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={
                () => this.handleRequestClose()} color="secondary">
                Close
              </Button>
            </DialogActions>
          </ConditionallyVisible>
          <ConditionallyVisible
            condition={reservationsPaymentCreationSuccess
              !== null && reservationsPaymentCreationSuccess}
          >
            <DialogContent>
              <DialogContentText>
                <div style={{ color: '#47FEB4', padding: '45px' }}>Success! Payment recorded</div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => this.handleRequestClose()}
               color="secondary">
                Close
              </Button>
            </DialogActions>
          </ConditionallyVisible> */}
        </Dialog>
      </div>
    );
  }
}

InvoiceRecordPaymentDialog.propTypes = {
  getCompanyInvoices: PropTypes.func.isRequired,
  updateRecordPaymentInvoiceStore: PropTypes.func.isRequired,
  requestRecordPaymentInvoice: PropTypes.func.isRequired,
  invoice: PropTypes.shape().isRequired,
  company: PropTypes.shape().isRequired,
  recordPaymentInvoice: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  recordPaymentInvoice: state.invoices.recordPaymentInvoice,
  company: state.companies.companyInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyInvoices: (a, b) => dispatch(actions.getCompanyInvoices(a, b)),
  updateRecordPaymentInvoiceStore: (keyValue) => dispatch(
    actions.updateRecordPaymentInvoiceStore(keyValue),
  ),
  requestRecordPaymentInvoice: (invoiceId) => dispatch(
    actions.requestRecordPaymentInvoice(invoiceId),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceRecordPaymentDialog);
