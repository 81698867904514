/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import { useMutation } from '@tanstack/react-query';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import { updateDeviceStatus } from '../../../services/companiesServices';
import { useCompany, useToast } from '../../../hooks';
import { OverlayLoader } from '../../../components/OverlayLoader';

export const DeleteDeviceDialog = ({ onSuccessCallback, node }) => {
  const company = useCompany();
  const toast = useToast();
  const { id: deviceNodeId, friendlyName } = node;
  const isLinkedToFacilities = node?.facilities?.length > 0;

  const { id: companyId } = company;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const mutation = useMutation({
    mutationFn: async () => {
      const res = await updateDeviceStatus(
        companyId,
        deviceNodeId,
        { status: 'DELETED' },
      );

      if (res.status !== 200) {
        throw new Error('Error deleting device');
      }

      return res.data;
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Device deleted successfully',
      });
      setIsDialogOpen(false);

      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'Error deleting device',
      });
    },
  });

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        style={{
          backgroundColor: 'var(--destructive)',
          color: 'white',
        }}
        startIcon={<DeleteTwoToneIcon />}
        onClick={handleOpen}
      >
        Delete Device
      </Button>

      <Dialog open={isDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
        {mutation.isLoading && <OverlayLoader />}

        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6">
              {`Delete device: ${friendlyName}?`}
            </Typography>
            <HighlightOffTwoToneIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
          </div>
        </DialogTitle>
        {!isLinkedToFacilities ? (

          <DialogContent>
            <Typography variant="body1">
              Please be aware that if you delete the following item:
              <strong>{` ${friendlyName} `}</strong>
              it will be
              <strong>{' permanently removed '}</strong>
              from the system.
            </Typography>
          </DialogContent>
        ) : (
          <DialogContent>
            <Typography variant="body1">
              This device is linked to
              <strong>{` ${node.facilities.length} `}</strong>
              facilities. Please unlink the device from the facilities before deleting it.
            </Typography>
          </DialogContent>
        )}

        <DialogActions>
          <Button variant="contained" onClick={handleClose} disabled={mutation.isLoading}>
            Cancel
          </Button>
          {!isLinkedToFacilities && (
            <Button
              variant="contained"
              onClick={() => mutation.mutate()}
              style={{
                backgroundColor: 'var(--destructive)',
                color: 'white',
              }}
              disabled={mutation.isLoading}
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
