import React, { useMemo } from 'react';
import {
  Input, InputLabel,
  FormControl,
  Typography,
} from '@material-ui/core';
import CheckBox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import SiteSelector from '../../../components/CompanySitePicker';
import { usePitchbookingUser } from '../../../hooks';

const addTeamMemberForm = (props) => {
  const {
    isPBAdmin,
    values,
    siteOptions,
    handleChange,
    companyTitles,
    roles,
    setFieldValue,
    basic,
  } = props;

  const { canEditTeamMembers } = usePitchbookingUser();

  const roleOptions = useMemo(() => {
    if (!isPBAdmin) {
      return roles.filter((r) => r.type === 'DEFAULT');
    }
    return roles;
  }, [roles, isPBAdmin]);

  return (
    <div>
      <Typography type="subheading">
        Add a new dashboard admin who will have access to this Manager Dashboard.
      </Typography>
      <Typography gutterBottom color="secondary" type="body2">
        This will automatically send an email to them.
      </Typography>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
      >
        <div style={{ display: 'flex' }}>
          <FormControl style={{
            marginRight: 20,
            marginTop: 20,
          }}
          >
            <InputLabel htmlFor="firstName">First Name</InputLabel>
            <Input id="firstName" value={values?.firstName} onChange={basic ? handleChange : handleChange('firstName')} />
          </FormControl>
          <FormControl style={{
            marginRight: 20,
            marginTop: 20,
          }}
          >
            <InputLabel htmlFor="lastName">Last Name</InputLabel>
            <Input id="lastName" value={values?.lastName} onChange={basic ? handleChange : handleChange('lastName')} />
          </FormControl>
        </div>
        <div style={{ display: 'flex' }}>
          <FormControl style={{
            marginRight: 20,
            marginTop: 20,
          }}
          >
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input id="email" value={values?.email} onChange={basic ? handleChange : handleChange('email')} />
          </FormControl>
          {!basic && (
          <FormControl style={{
            marginTop: 25,
            flexDirection: 'row',
          }}
          >
            <p>Receives Booking Confirmation Emails</p>
            <CheckBox
              id="receivesEmails"
              checked={values?.receivesEmails}
              onChange={
              (event) => setFieldValue('receivesEmails', event.target.checked)
              }
            />
          </FormControl>
          )}
        </div>
        <div>
          {companyTitles && companyTitles.length > 0 && (
          <FormControl style={{
            marginRight: 20,
            marginTop: 20,
          }}
          >
            <Select
              displayEmpty
              id="jobRole"
              defaultValue="NOT SPECIFIED"
              value={values?.jobRole}
              name="jobRole"
              label="Role"
              onChange={handleChange('jobRole')}
            >
              <MenuItem value="NOT SPECIFIED">Select Job Role</MenuItem>
              {companyTitles.map((r) => (
                <MenuItem value={r}>{r}</MenuItem>
              ))}
            </Select>
          </FormControl>
          )}
          {!basic && (
          <FormControl style={{
            marginTop: 25,
            flexDirection: 'row',
          }}
          >
            <p>Contact Details Are Publicly Displayed?</p>
            <CheckBox
              checked={values?.visibleContact}
              onChange={
              (event) => setFieldValue('visibleContact', event.target.checked)
              }
            />
          </FormControl>
          )}
        </div>
        <ConditionallyVisible condition={values.visibleContact}>
          <div>
            <FormControl style={{
              marginRight: 20,
              marginTop: 20,
            }}
            >
              <InputLabel htmlFor="publicDisplayName">Public Display Name</InputLabel>
              <Input id="publicDisplayName" value={values.publicDisplayName} onChange={handleChange('publicDisplayName')} />
            </FormControl>
          </div>
        </ConditionallyVisible>
        <div>
          {siteOptions && siteOptions.length > 0 && (
          <FormControl style={{
            marginRight: 20,
            marginTop: 20,
          }}
          >
            <SiteSelector
              selectedSite={values?.selectedSite}
              sites={siteOptions}
              onChange={handleChange('selectedSite')}
            />
          </FormControl>
          )}
          <ConditionallyVisible condition={roles && roles.length > 0 && canEditTeamMembers}>
            <FormControl style={{
              marginRight: 20,
              marginTop: 20,
            }}
            >
              <Select
                displayEmpty
                value={values?.role}
                id="role"
                name="role"
                label="Role"
                onChange={handleChange('role')}
              >
                {roleOptions.map((r) => (
                  <MenuItem value={r.id}>{r.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </ConditionallyVisible>
        </div>
      </div>
    </div>
  );
};

export default addTeamMemberForm;
