/* eslint-disable react/prop-types */
import React from 'react';
import {
  Typography, Card, CardMedia, Button, CardActions,
} from '@material-ui/core';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import { LibraryItemDialog } from './LibraryItemDialog';
import { DeleteLibraryItemDialog } from './DeleteLibraryItemDialog';

export const LibraryCard = ({ noteData, colorScheme, fetchNotes }) => {
  const {
    name, value, url, imageUrl,
  } = noteData;
  const borderColor = colorScheme ? colorScheme?.primary?.color : '#e0e0e0';

  return (
    noteData && (
    <Card
      style={{
        padding: '0rem 1rem 1rem 1rem',
        backgroundColor: '#fff',
        border: `1px solid ${borderColor}`,
        borderRadius: '0.75rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ flexGrow: 1 }}>
        {imageUrl ? (
          <CardMedia
            style={{
              width: '100%',
              height: '15rem',
              marginBottom: '1rem',
            }}
            image={imageUrl}
          />
        ) : (
          <div style={{
            width: '100%',
            height: '15rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
            <Typography style={{ fontSize: '5rem' }}>😳</Typography>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              {`Ooops! Looks like your image is missing.
              Click 'Edit' to add an imageUrl or upload your own.`}
            </Typography>
          </div>
        )}

        <Typography variant="body1" style={{ fontWeight: '600' }}>
          {name}
        </Typography>

        <Typography variant="body2">
          {value.length > 100 ? `${value.slice(0, 100)}...` : value}
        </Typography>
      </div>

      <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
        <DeleteLibraryItemDialog data={noteData} onSuccessCallback={fetchNotes} />

        <LibraryItemDialog isEditing editData={noteData} onSuccessCallback={fetchNotes} />

        <Button
          variant="contained"
          color="primary"
          startIcon={<ExitToAppTwoToneIcon />}
          href={url}
          target="_blank"
        >
          Open
        </Button>
      </CardActions>
    </Card>
    )
  );
};
