// @flow weak

import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Typography } from '@material-ui/core';
import { bookingModifierColors } from '../containers/bookingModifiers/components/constants';

function FacilityChips({ facilities }) {
  return (
    <div style={{
      display: 'flex', gap: '0.75rem', flexWrap: 'wrap',
    }}
    >
      {facilities
      && facilities?.sort((a, b) => a.name.localeCompare(b.name))
        .map((f) => (
          <Chip
            key={f.id}
            label={(
              <Typography variant="body2">
                {f.name}
              </Typography>
          )}
            style={{
              border: bookingModifierColors.facility.border,
              backgroundColor:
              bookingModifierColors.facility.backgroundColor,
              color: bookingModifierColors.facility.color,
            }}
          />
        ))}
    </div>
  );
}

FacilityChips.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default (FacilityChips);
