/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { DayPickerSingleDateController } from 'react-dates';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import EditIcon from '@material-ui/icons/Edit';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as reservationsActions from '../../../reducers/reservationsReducer';
import { updatePOSOrder, updatePOSTotal } from '../../../reducers/addonsReducer';
import { paymentMethodButtonsData } from '../../invoices/InvoiceRecordPaymentDialog';
import ToggleButtons from '../../../components/ToggleButtons';
import {
  useCompany, useMobile, useToast,
} from '../../../hooks';
import UserSelector from '../../../components/UserSelector';
import Terminal from '../../../components/Stripe/Terminal';
import ProductSelection from '../../pos/ProductSelection';
import { CardNotPresent } from './CardNotPresent';
import { ExcludeCalendarSlot } from './excludeCalendarSlot';
import FacilityUpdateDialog from '../../../components/FacilityUpdateDialog';
import { getCalendarUsage } from '../../../reducers/calendarReducer';

const UserOrNonUserSelection = ({
  user,
  isAnonymousPayment,
  note,
  updateRecordPaymentStore,
  paymentMethod,
}) => {
  const renderAnonymousPaymentCheckbox = () => {
    if (user?.id) return null;
    return (
      <Box display="flex" alignItems="center" mb={2}>
        <FormGroup row>
          <FormControlLabel
            control={(
              <Checkbox
                checked={isAnonymousPayment ?? false}
                onChange={(e) => {
                  updateRecordPaymentStore({
                    isAnonymousPayment: e.target.checked,
                    user: null,
                  });
                }}
              />
            )}
            label="Payment Without Customer Account"
          />
        </FormGroup>
      </Box>
    );
  };

  const renderSelectedCustomer = () => (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>
        Selected Customer:
      </Typography>
      <Typography variant="body1" style={{ marginRight: '1rem' }}>
        {`${user.firstName} ${user.lastName}${user.teamName ? ` (${user.teamName})` : ''}`}
      </Typography>
      <IconButton
        onClick={() => {
          updateRecordPaymentStore({
            user: null,
          });
        }}
      >
        <EditIcon />
      </IconButton>
    </Box>
  );

  const renderAnonymousPaymentDetails = () => (
    <>
      <Typography variant="body2" style={{ marginBottom: '1rem' }}>
        {'This payment will be recorded without creating a user profile. You can add the payer\'s name in the note field for admin purposes.'}
      </Typography>
      <PBInput
        label="Note / Name"
        type="text"
        value={note}
        onChange={(e) => {
          updateRecordPaymentStore({
            note: e.target.value,
          });
        }}
        disabled={paymentMethod === 'CARD_NOT_PRESENT' || paymentMethod === 'TERMINAL'}
      />
    </>
  );

  const renderUserSelector = () => (
    <UserSelector
      onChange={(e) => {
        const updatedUser = {
          ...user,
          ...e,
        };
        updateRecordPaymentStore({
          user: updatedUser,
        });
      }}
      user={user}
      showAlert={false}
    />
  );

  const renderContent = () => {
    if (user?.id) {
      return renderSelectedCustomer();
    }
    if (isAnonymousPayment) {
      return renderAnonymousPaymentDetails();
    }
    return renderUserSelector();
  };

  return (
    <Box>
      {renderAnonymousPaymentCheckbox()}
      {renderContent()}
    </Box>
  );
};

const PaymentProcessing = ({ isPaymentProcessing }) => (isPaymentProcessing ? (
  <div style={{
    position: 'absolute',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 999,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }}
  >
    <CircularProgress />
    <Typography variant="h5" style={{ marginTop: '1rem', color: 'rgba(255, 255, 255, 0.9)' }}>Processing payment...</Typography>
  </div>
) : null);

const PartialPaymentsLog = ({
  currencySym, partialPaymentOrders,
}) => (partialPaymentOrders?.length > 0 ? (
  <div style={{ marginBottom: '1rem' }}>
    <Typography gutterBottom="4">
      Partial Payments Made
    </Typography>

    <List dense style={{ padding: 0 }}>
      {partialPaymentOrders.map((x, index) => {
        const name = x.isAnonymousPayment ? 'No Account' : `${x.user?.firstName} ${x.user?.lastName}`;
        const amount = `${currencySym}${Number(x.total / 100).toFixed(2)}`;
        return (
          <ListItem key={x.id} disableGutters style={{ padding: 0 }}>
            <ListItemText>
              {`${index + 1}. ${name} - ${amount}`}
              <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                {x.note && ` - ${x.note}`}
              </span>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  </div>
) : null);

const RecordPaymentDialog = ({
  resetSelectedRows,
  deselectAll,
  singularBooking,
  calendarPayment = false,
  requestMultipleRecordPayment,
  buttonTitle,
  dialogTitle,
  subscriptionSelected,
  subscriptionLink,
  subscription,
}) => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const toast = useToast();
  const {
    isPowerleague, timezone, currencySym, products, additionalPaymentMethods,
  } = useCompany();

  const reservation = useSelector((state) => state.reservation);
  const bookings = useSelector((state) => state.bookings);
  const selectedReservations = bookings.selectedRows;
  const isReservation = selectedReservations?.[0]?.type === 'RESERVATION';
  const siteId = isReservation ? selectedReservations?.[0]?.siteId
    : selectedReservations?.[0]?.accessRestriction?.facilities?.find(
      (f) => f?.siteId !== null,
    )?.siteId;

  const { posOrder, posTotal } = useSelector((state) => state.addons);
  const [open, setOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [subToEdit, setSubToEdit] = useState(null);

  const updateRecordPaymentStore = (keyValue) => dispatch(
    reservationsActions.updateRecordPaymentStore(keyValue),
  );

  const recordMultipleReservationPaymentReset = () => dispatch(
    reservationsActions.recordMultipleReservationPaymentReset(),
  );

  const handleRequestClose = () => {
    let { dates } = bookings;
    if (!dates.fromDate) {
      dates = {
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().add(7, 'days').format('YYYY-MM-DD'),
      };
    }
    recordMultipleReservationPaymentReset();

    if (deselectAll) {
      deselectAll();
    }
    if (!singularBooking) {
      resetSelectedRows();
    }

    batch(() => {
      dispatch(reservationsActions.resetRecordPaymentStore());
      dispatch(updatePOSOrder([]));
      dispatch(updatePOSTotal(0));
    });

    setOpen(false);
    setButtonDisabled(false);
  };

  const performAction = () => {
    setButtonDisabled(true);
    updateRecordPaymentStore({
      isPaymentProcessing: true,
    });
    requestMultipleRecordPayment(singularBooking);
  };

  const handlePaidAt = (date) => {
    updateRecordPaymentStore({
      paidAt: date.tz(timezone).format(),
    });
  };

  const {
    reservationsPaymentCreationSuccess, reservationsPaymentCreationError, recordPaymentBookings,
  } = reservation;
  const {
    paymentType, paymentMethod, paidAt, user, numberOfPlayers,
    amount, note, isAnonymousPayment, isPaymentProcessing,
  } = recordPaymentBookings;

  let paymentTypes = [
    {
      buttonTitle: 'Full Balance',
      buttonValue: 'FULL_AMOUNT',
    },
  ];

  const isMultiplePayment = selectedReservations?.length > 1;
  let isReservationPayment = selectedReservations?.every((x) => x.type === 'RESERVATION');
  const isPOSReservationPayment = selectedReservations?.every((x) => x.type === 'POS');
  let isSubscriptionPayment = selectedReservations?.every((x) => x.type === 'SUBSCRIPTION');
  let splitBetweenNumberOfPlayers = null;

  const booking = selectedReservations?.length === 1 ? selectedReservations[0] : null;
  if (booking) {
    isReservationPayment = booking?.type === 'RESERVATION';
    isSubscriptionPayment = booking?.type === 'SUBSCRIPTION';
    splitBetweenNumberOfPlayers = booking?.orders?.[0]?.splitBetweenNumberOfPlayers ?? null;
  }

  const isPartialBookingsEnabled = products?.partialPayments === 'ENABLED' && booking && booking.type !== 'POS';
  const isTerminalEnabled = products?.terminal === 'ENABLED';
  const isEditSubscriptionSlotEnabled = products?.calendarInteractable === 'ENABLED';

  if (isPartialBookingsEnabled) {
    paymentTypes = [
      {
        buttonTitle: 'Full Balance',
        buttonValue: 'FULL_AMOUNT',
      },
      {
        buttonTitle: 'Custom Amount',
        buttonValue: 'CUSTOM_AMOUNT',
      },
      {
        buttonTitle: 'Equal Split',
        buttonValue: 'SPLIT_AMOUNT',
      },
    ];
  }

  const isBookingPaid = useMemo(() => {
    if (selectedReservations?.some((x) => x.paid)) {
      return true;
    }

    return false;
  }, [selectedReservations]);

  const isUnconfirmedSubscription = useMemo(() => {
    if (selectedReservations?.some((x) => x.unconfirmedSubscription)) {
      return true;
    }

    return false;
  }, [selectedReservations]);

  const isBookingProcessing = useMemo(() => {
    if (selectedReservations?.some((x) => x.status === 'PENDING')) {
      return true;
    }

    return false;
  }, [selectedReservations]);

  const totalPaid = useMemo(() => booking?.partialPaymentOrders?.reduce(
    (acc, curr) => acc + (curr.total / 100), 0,
  ) ?? 0, [booking]);

  const remainingToPay = useMemo(() => {
    if (totalPaid) {
      return parseFloat((Math.ceil((booking.total - totalPaid) * 100) / 100).toFixed(2));
    }
    return parseFloat((Math.ceil(booking?.total * 100) / 100).toFixed(2));
  }, [booking, totalPaid]);

  const isFormValid = useMemo(() => {
    if (
      booking
      && amount
      && Number(amount) > Number(remainingToPay)
    ) {
      return false;
    }

    if (paymentMethod === 'TERMINAL') {
      return false;
    }

    if (paymentType !== 'FULL_AMOUNT') {
      if (!amount || Number(amount) <= 0) {
        return false;
      }

      if (paymentType === 'SPLIT_AMOUNT' || paymentType === 'CUSTOM_AMOUNT') {
        if (!user && !isAnonymousPayment) {
          return false;
        }
        if (booking && (totalPaid + Number(amount)) > booking.total) {
          return false;
        }
      }

      if (paymentType === 'SPLIT_AMOUNT' && !numberOfPlayers) {
        return false;
      }
    }

    return true;
  }, [
    paymentMethod, user, amount, numberOfPlayers,
    paidAt, isAnonymousPayment, booking, remainingToPay, paymentType,
  ]);

  const resetRecordPaymentStore = () => updateRecordPaymentStore({
    user: null,
    isPaymentProcessing: false,
    paymentMethod: 'BANKTRANSFER',
    note: null,
  });

  useEffect(() => {
    if (calendarPayment) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    updateRecordPaymentStore({
      reservationId: booking?.id ?? null,
    });

    setButtonDisabled(false);
  }, [booking]);

  useEffect(() => {
    if (paymentType === 'SPLIT_AMOUNT' && amount) {
      const newAmount = (Math.ceil(Number(booking.total / numberOfPlayers) * 100) / 100).toFixed(2);

      updateRecordPaymentStore({
        amount: newAmount < remainingToPay ? newAmount : remainingToPay,
      });
    }
  }, [booking, paymentType, numberOfPlayers, remainingToPay]);

  useEffect(() => {
    if (isAnonymousPayment
      && (paymentMethod === 'CARD_NOT_PRESENT')
    ) {
      updateRecordPaymentStore({
        paymentMethod: 'BANKTRANSFER',
      });
    }
  }, [isAnonymousPayment, paymentMethod]);

  useEffect(() => {
    if (paymentMethod === 'CARD_NOT_PRESENT' || paymentMethod === 'TERMINAL') {
      updateRecordPaymentStore({
        paymentMethod: 'BANKTRANSFER',
      });
    }
  }, [paymentType]);

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
      >
        {buttonTitle}
      </Button>
      <Dialog open={open} fullScreen={isMobile} fullWidth maxWidth="md">
        <PaymentProcessing isPaymentProcessing={isPaymentProcessing} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              flex: '1 1 auto',
            }}
          >
            {dialogTitle}
          </DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => handleRequestClose()} />
          </DialogActions>
        </div>

        {(isBookingPaid || isBookingProcessing || isUnconfirmedSubscription) ? (
          <>
            <DialogContent>
              <Typography variant="h5" color="secondary" gutterBottom={1}>
                {(() => {
                  if (isBookingPaid) {
                    return 'This booking has successfully been recorded as paid';
                  }
                  if (isBookingProcessing) {
                    return 'This booking is currently processing payment';
                  }
                  if (isUnconfirmedSubscription) {
                    return 'This booking has an unconfirmed subscription';
                  }
                  return 'Payment status unknown.';
                })()}
              </Typography>

              <Typography>
                {isBookingPaid && 'One or more of the bookings that you have selected have already been paid for.'}
                {isBookingProcessing && 'The payment for this booking is currently processing. Please wait for confirmation.'}
                {isUnconfirmedSubscription && (
                  'The booking includes a subscription that has not been confirmed by the booker yet. Please wait for the booker to complete their subscription, which will ensure automatic payment. If the subscription is not completed by the time of the slot, you will be able to record the payment manually.'
                )}
              </Typography>
            </DialogContent>
            <DialogActions>
              {subscriptionLink && (
              <Link to={subscriptionLink}>
                <Button variant="contained" color="secondary">
                  View Subscription
                </Button>
              </Link>
              )}

              {subscriptionSelected && subscription && !isBookingPaid && (
                <ExcludeCalendarSlot subscription={subscription} />
              )}
              {isEditSubscriptionSlotEnabled && (
              <>
                <Button variant="contained" color="secondary" onClick={() => { setIsEditOpen(true); setSubToEdit(subscription); }}>Edit Slot</Button>
                <FacilityUpdateDialog
                  allocation={isEditOpen ? subToEdit : null}
                  subscription={subToEdit}
                  onClose={() => {
                    setIsEditOpen(false); setSubToEdit(null); handleRequestClose();
                  }}
                />
              </>
              )}
              <Button variant="outlined" onClick={() => handleRequestClose()} color="secondary">
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <ConditionallyVisible condition={reservationsPaymentCreationSuccess === null}>
              <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>

                {/* Totals */}
                {isPartialBookingsEnabled && (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '1rem' }}>
                      <div style={{ textAlign: 'center' }}>
                        <DialogContentText style={{ marginBottom: 0.1 }}>
                          Total
                        </DialogContentText>
                        <Typography>
                          {`${currencySym}${booking?.total?.toFixed(2)}`}
                        </Typography>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <DialogContentText style={{ marginBottom: 0.1 }}>
                          Paid
                        </DialogContentText>
                        <Typography>
                          {`${currencySym}${totalPaid.toFixed(2)}`}
                        </Typography>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <DialogContentText style={{ marginBottom: 0.1 }}>
                          Unpaid
                        </DialogContentText>
                        <Typography>
                          {`${currencySym}${(booking?.totalPaid ? (booking?.total - booking?.totalPaid) : booking?.total)?.toFixed(2)}`}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                {/* Only show payment type if there is more than one option */}
                {paymentTypes.length > 1 && (
                  <div>
                    <Typography gutterBottom="4">
                      Payment Type
                    </Typography>
                    <ToggleButtons
                      buttonsData={paymentTypes}
                      changeOption={(option) => updateRecordPaymentStore({
                        paymentType: option,
                        user: null,
                        amount: splitBetweenNumberOfPlayers
                          ? Number(booking.total / splitBetweenNumberOfPlayers).toFixed(2)
                          : null,
                        numberOfPlayers: splitBetweenNumberOfPlayers,
                        isAnonymousPayment: false,
                        note: null,
                      })}
                      value={paymentType}
                    />
                  </div>
                )}

                {/* Conditionally rendered if partial payments are made */}
                <PartialPaymentsLog
                  currencySym={currencySym}
                  partialPaymentOrders={booking?.partialPaymentOrders}
                />

                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  {paymentType === 'SPLIT_AMOUNT' && (
                    <>
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <PBInput
                          label="How many players?"
                          type="number"
                          value={numberOfPlayers}
                          onChange={(e) => {
                            const value = Number(e.target.value);
                            let amount = Number(booking.total / value).toFixed(2);

                            if (amount > (
                              totalPaid
                                ? (booking.total - totalPaid)
                                : booking.total
                            )) {
                              amount = (booking.total - totalPaid).toFixed(2);
                            }

                            updateRecordPaymentStore({
                              numberOfPlayers: value,
                              amount,
                            });
                          }}
                        />
                        <PBInput
                          label="Amount"
                          type="currency"
                          currencySymbol={currencySym}
                          value={amount}
                          onChange={(e) => {
                            updateRecordPaymentStore({
                              amount: e.target.value,
                            });
                          }}
                          isError={
                            amount > remainingToPay
                          }
                          errorMessage={
                            amount > remainingToPay
                              ? 'Amount cannot be greater than amount owed'
                              : null
                          }
                          disabled={paymentMethod === 'CARD_NOT_PRESENT' || paymentMethod === 'TERMINAL'}
                        />
                      </div>

                      <UserOrNonUserSelection
                        user={user}
                        isAnonymousPayment={isAnonymousPayment}
                        note={note}
                        updateRecordPaymentStore={updateRecordPaymentStore}
                        paymentMethod={paymentMethod}
                      />
                    </>
                  )}

                  {paymentType === 'CUSTOM_AMOUNT' && (
                    <>
                      <PBInput
                        label="Amount"
                        type="currency"
                        currencySymbol={currencySym}
                        value={amount}
                        onChange={(e) => {
                          updateRecordPaymentStore({
                            amount: e.target.value,
                          });
                        }}
                        isError={
                        amount > (totalPaid
                          ? (booking.total - totalPaid)
                          : booking.total)
                      }
                        errorMessage={amount > (
                          totalPaid
                            ? (booking.total - totalPaid) : booking.total
                        ) ? 'Amount cannot be greater than amount owed' : null}
                        disabled={paymentMethod === 'CARD_NOT_PRESENT' || paymentMethod === 'TERMINAL'}
                      />

                      <UserOrNonUserSelection
                        user={user}
                        isAnonymousPayment={isAnonymousPayment}
                        note={note}
                        updateRecordPaymentStore={updateRecordPaymentStore}
                        paymentMethod={paymentMethod}
                      />
                    </>
                  )}

                  <ProductSelection
                    paymentMethod={paymentMethod}
                    siteId={siteId}
                  />

                  {/* Payment Method Selection */}
                  {((paymentType === 'FULL_AMOUNT')
                    || (paymentType === 'SPLIT_AMOUNT' && (user || isAnonymousPayment))
                    || (paymentType === 'CUSTOM_AMOUNT' && amount && (user || isAnonymousPayment))) && (
                    <>
                      <div>
                        <Typography gutterBottom="4">
                          Payment method:
                        </Typography>
                        <ToggleButtons
                          buttonsData={
                            paymentMethodButtonsData(
                              !subscriptionSelected && paymentType === 'FULL_AMOUNT',
                              isPowerleague,
                              isTerminalEnabled,
                              additionalPaymentMethods,
                              products?.cardNotPresent === 'ENABLED',
                              isAnonymousPayment,
                            )
                          }
                          changeOption={(option) => updateRecordPaymentStore({
                            paymentMethod: option,
                          })}
                          value={paymentMethod}
                        />
                      </div>
                      {
                        paymentMethod === 'TERMINAL'
                        && (paymentType === 'FULL_AMOUNT' || (paymentType !== 'FULL_AMOUNT' && amount))
                        && (isReservationPayment || isSubscriptionPayment
                           || isMultiplePayment || isPOSReservationPayment) && (
                           <div style={{ display: 'flex', marginTop: '2rem' }}>
                             {isMultiplePayment ? (
                               <Terminal
                                 transactionType="MULTIPLE_PAYMENT"
                                 transactionId={null}
                                 metadata={{
                                   reservations: selectedReservations.map((x) => ({
                                     id: x.id,
                                     type: x.type,
                                     slot: x.slot,
                                   })),
                                   ...(user && {
                                     ...(user.id ? { userId: user.id } : {
                                       user: JSON.stringify({
                                         id: user.id,
                                         firstName: user.firstName,
                                         lastName: user.lastName,
                                         teamName: user.teamName,
                                         phoneNumber: user.phone,
                                         email: user.email,
                                       }),
                                     }),
                                   }),
                                   isAnonymousPayment,
                                   note,
                                   posOrder,
                                 }}
                                 userId={user?.id ?? null}
                                 amount={
                                  selectedReservations.reduce((acc, x) => acc + x.total, 0)
                                }
                                 onSuccess={() => {
                                   toast.trigger({
                                     message: 'Payment Successful!',
                                     type: 'success',
                                   });

                                   handleRequestClose();
                                   selectedReservations.forEach((x) => {
                                     dispatch(
                                       reservationsActions.fetchReservationSelectedRow(
                                         x.id, x.type,
                                       ),
                                     );
                                   });
                                   resetRecordPaymentStore();
                                   dispatch(getCalendarUsage());
                                 }}
                               />
                             ) : (
                               <Terminal
                                 transactionType={
                                  isReservationPayment ? 'RESERVATION_PAYMENT' : 'SUBSCRIPTION_PAYMENT'
                                }
                                 transactionId={booking?.id}
                                 metadata={
                                  {
                                    ...(isSubscriptionPayment ? {
                                      slot: booking.slot,
                                    } : {
                                    }),
                                    ...(user && {
                                      ...(user.id ? { userId: user.id } : {
                                        user: JSON.stringify({
                                          id: user.id,
                                          firstName: user.firstName,
                                          lastName: user.lastName,
                                          teamName: user.teamName,
                                          phoneNumber: user.phone,
                                          email: user.email,
                                        }),
                                      }),
                                    }),
                                    isAnonymousPayment,
                                    note,
                                    posOrder,
                                  }
                                }
                                 userId={user?.id ?? null}
                                 amount={amount}
                                 onSuccess={() => {
                                   dispatch(
                                     reservationsActions.fetchReservationSelectedRow(
                                       booking.id, booking.type,
                                     ),
                                   );
                                   toast.trigger({
                                     message: 'Payment Successful!',
                                     type: 'success',
                                   });

                                   resetRecordPaymentStore();
                                   dispatch(getCalendarUsage());

                                   if (paymentType === 'FULL_AMOUNT') {
                                     handleRequestClose();
                                   }
                                 }}
                               />
                             )}

                           </div>
                        )
                      }

                      <CardNotPresent
                        paymentMethod={paymentMethod}
                        booking={booking}
                        metadata={
                          {
                            ...(paymentType !== 'FULL_AMOUNT' ? {
                              amount: (Number((amount || 0)) + Number(posTotal)).toFixed(2),
                            } : {}),
                            ...(user && {
                              ...(user.id ? { userId: user.id } : {
                                user: JSON.stringify({
                                  id: user.id,
                                  firstName: user.firstName,
                                  lastName: user.lastName,
                                  teamName: user.teamName,
                                  phoneNumber: user.phone,
                                  email: user.email,
                                }),
                              }),
                            }),
                            ...(numberOfPlayers ? {
                              numberOfPlayers,
                            } : {}),
                          }
                        }
                        preSubmitAction={() => {
                          updateRecordPaymentStore({
                            isPaymentProcessing: true,
                          });
                        }}
                        onSuccess={() => {
                          toast.trigger({
                            message: 'Payment Successful!',
                            type: 'success',
                          });

                          // If it is a full payment close the dialog
                          if (paymentType === 'FULL_AMOUNT') {
                            handleRequestClose();
                          }

                          dispatch(
                            reservationsActions.fetchReservationSelectedRow(
                              booking.id, booking.type,
                            ),
                          );
                          dispatch(getCalendarUsage());
                          resetRecordPaymentStore();
                        }}
                      />

                      <ConditionallyVisible condition={paymentMethod !== 'NOT_CHARGED' && paymentMethod !== 'TERMINAL' && paymentMethod !== 'CARD_NOT_PRESENT'}>
                        <div>
                          <Typography gutterBottom="4">
                            Payment date:
                          </Typography>
                          <DayPickerSingleDateController
                            onDateChange={(date) => handlePaidAt(date)}
                            date={paidAt !== null ? moment(paidAt) : moment()}
                            noBorder
                          />
                        </div>
                      </ConditionallyVisible>
                    </>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <div>
                  <ConditionallyVisible condition={paymentType !== 'FULL_AMOUNT'}>
                    <p>
                      Paying Total Amount:
                      {` ${currencySym}${(Number((amount || 0)) + Number(posTotal)).toFixed(2)}`}
                    </p>
                  </ConditionallyVisible>
                  <ConditionallyVisible condition={paymentType === 'FULL_AMOUNT'}>
                    {isMultiplePayment ? (
                      <p>
                        Paying Total Amount:
                        {` ${currencySym}${(selectedReservations.reduce((acc, x) => acc + x.total, 0) + Number(posTotal))?.toFixed(2)}`}
                      </p>
                    ) : (
                      <p>
                        Paying Total Amount:
                        {` ${currencySym}${(booking?.totalPaid ? (booking?.total - booking?.totalPaid) + Number(posTotal) : booking?.total + Number(posTotal))?.toFixed(2)}`}
                      </p>
                    )}
                  </ConditionallyVisible>
                </div>
                <div style={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  {calendarPayment ? (
                    <Button variant="outlined" onClick={() => handleRequestClose()} color="secondary">
                      Close
                    </Button>
                  ) : (
                    <Button variant="outlined" onClick={() => handleRequestClose()} color="secondary">
                      Go back
                    </Button>
                  )}
                  {subscriptionLink && (
                  <Link to={subscriptionLink}>
                    <Button variant="contained" color="secondary">
                      View Subscription
                    </Button>
                  </Link>
                  )}

                  {subscriptionSelected && subscription && !isBookingPaid && (
                  <ExcludeCalendarSlot subscription={subscription} />
                  )}
                  {isEditSubscriptionSlotEnabled && (
                  <>
                    <Button variant="contained" color="secondary" onClick={() => { setIsEditOpen(true); setSubToEdit(subscription); }}>Edit Slot</Button>
                    <FacilityUpdateDialog
                      allocation={isEditOpen ? subToEdit : null}
                      subscription={subToEdit}
                      onClose={() => {
                        setIsEditOpen(false); setSubToEdit(null); handleRequestClose();
                      }}
                    />
                  </>
                  )}
                  {paymentMethod !== 'CARD_NOT_PRESENT' && (
                  <Button
                    id="manager-bookings-record-payment"
                    disabled={buttonDisabled || !isFormValid}
                    variant="contained"
                    onClick={() => performAction()}
                    color="primary"
                  >
                    Record Payment
                  </Button>
                  )}
                </div>
              </DialogActions>
            </ConditionallyVisible>

            <ConditionallyVisible condition={reservationsPaymentCreationSuccess === false}>
              <DialogContent>
                <DialogContentText>
                  <div style={{ color: '#ac372f', padding: '45px' }}>Error! There was a problem recording payment for these reservations.</div>
                  <ConditionallyVisible condition={reservationsPaymentCreationError !== null}>
                    <div style={{ color: '#ac372f', padding: '45px' }}>{reservationsPaymentCreationError}</div>
                  </ConditionallyVisible>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={() => handleRequestClose()} color="secondary">
                  Close
                </Button>
              </DialogActions>
            </ConditionallyVisible>

            <ConditionallyVisible
              condition={reservationsPaymentCreationSuccess !== null
              && reservationsPaymentCreationSuccess}
            >
              <DialogContent>
                <DialogContentText>
                  <div style={{ color: '#47FEB4', padding: '45px' }}>Success! Payment recorded</div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={() => handleRequestClose()} color="secondary">
                  Close
                </Button>
              </DialogActions>
            </ConditionallyVisible>
          </>
        )}

      </Dialog>
    </div>
  );
};

RecordPaymentDialog.propTypes = {
  deselectAll: PropTypes.func.isRequired,
  requestMultipleRecordPayment: PropTypes.func.isRequired,
  resetSelectedRows: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  singularBooking: PropTypes.bool.isRequired,
  subscriptionSelected: PropTypes.bool,
  calendarPayment: PropTypes.bool,
  subscriptionLink: PropTypes.string,
  // selectedReservations: PropTypes.arrayOf({}).isRequired,
  subscription: PropTypes.shape(),
};

RecordPaymentDialog.defaultProps = {
  subscriptionSelected: false,
  calendarPayment: false,
  subscriptionLink: null,
  subscription: null,
};

UserOrNonUserSelection.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    teamName: PropTypes.string,
  }).isRequired,
  isAnonymousPayment: PropTypes.bool.isRequired,
  note: PropTypes.string.isRequired,
  updateRecordPaymentStore: PropTypes.func.isRequired,
};

export default RecordPaymentDialog;
