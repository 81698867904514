import React from 'react';
import pbLogo from '../images/logo-black.png';

const MaintenanceMode = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    marginLeft: '28%',
    marginRight: '28%',
    textAlign: 'center',
  }}
  >
    <img src={pbLogo} alt="Pitchbooking Manager" height="35px" />
    <h1>Site is undergoing scheduled maintenance</h1>
    <h2>
      During this time, some features may be temporarily unavailable.
    </h2>
    <h3>
      We apologise for any inconvenience and thank you for your patience
      as we work to improve your Pitchbooking experience.

      We will be back up and running as quickly as possible!
    </h3>
  </div>
);

export default MaintenanceMode;
