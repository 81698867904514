export const categoryOptions = [
  { label: 'FA', value: 'FA' },
  { label: 'Club', value: 'CLUB' },
  { label: 'Commercial Centre', value: 'COMMERCIAL_CENTRE' },
  { label: 'Council', value: 'COUNCIL' },
  { label: 'School', value: 'SCHOOL' },
  { label: 'University', value: 'UNIVERSITY' },
  { label: 'Charity / Trust', value: 'CHARITY_TRUST' },
  { label: 'Other', value: 'OTHER' },
];

export const regionOptions = [
  { label: 'Northern Ireland', value: 'NORTHERN_IRELAND' },
  { label: 'Scotland', value: 'SCOTLAND' },
  { label: 'Wales', value: 'WALES' },
  { label: 'England', value: 'ENGLAND' },
  { label: 'Ireland', value: 'IRELAND' },
  { label: 'UK Wide', value: 'UK_WIDE' },
  { label: 'US', value: 'US' },
  { label: 'Rest of the World', value: 'RoW' },
];

export const sourceOptions = [
  { label: 'Inbound', value: 'INBOUND' },
  { label: 'Outbound', value: 'OUTBOUND' },
];
