import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import ActionBar from '../../components/ActionBar';
import RunDbHealthCheckButton from '../../components/RunDbHealthCheckButton';
import RunDoubleBookingCheckButton from '../../components/RunDoubleBookingCheckButton';
import RunCronJobSelectorAndButton from '../../components/RunCronJobSelectorAndButton';
import CreateCompanyDialog from '../../components/CreateCompanyDialog';
import ReauthoriseXeroButton from '../../components/ReauthoriseXeroButton';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';
import * as actions from '../../reducers/utilsReducer';
import { refreshLeagues } from '../../services/utilsServices';
import CreateNewDemoDialog from '../createDemo/CreateNewDemoDialog';
import { ValidateMembershipSubscriptionsButton } from './ValidateMembershipSubscriptionsButton';

class Utils extends React.Component {
  componentDidMount() {
    const { doRequestXeroExchangeCode } = this.props;
    const url = window.location.href;
    if (url.includes('xero-auth')) {
      doRequestXeroExchangeCode(url);
    }
  }

  render() {
    const { companies } = this.props;
    const { id } = companies.companyInfo;
    const localOrDemo = window.location.href.includes('manager-demo.pitchbooking.co') || window.location.href.includes('localhost:4999');

    return (
      <>
        <PitchbookingUserOnly>
          <ActionBar>
            <RunDbHealthCheckButton />
            <RunDoubleBookingCheckButton />
            <CreateCompanyDialog />
            <Button
              variant="contained"
              color="primary"
              onClick={() => refreshLeagues(id)}
            >
              Refresh Leagues
            </Button>
          </ActionBar>
          <ActionBar>
            <RunCronJobSelectorAndButton />
          </ActionBar>
          <ActionBar>
            <ReauthoriseXeroButton />
            <ValidateMembershipSubscriptionsButton />
          </ActionBar>
        </PitchbookingUserOnly>
        <ConditionallyVisible condition={localOrDemo}>
          <ActionBar>
            <CreateNewDemoDialog />
          </ActionBar>
        </ConditionallyVisible>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, companies } = state;
  const { isLoading } = users;
  return {
    users, isLoading, companies,
  };
}

Utils.propTypes = {
  doRequestXeroExchangeCode: PropTypes.func.isRequired,
  companies: PropTypes.shape().isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  doRequestXeroExchangeCode: (code) => dispatch(actions.requestXeroExchangeCode(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Utils);
