import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';

export const GET_COMPANIES = 'GET_COMPANIES';
export const UPDATE_COMPANIES = 'UPDATE_COMPANIES';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const TOGGLE_NEW_MEMBERSHIP_DIALOG = 'TOGGLE_NEW_MEMBERSHIP_DIALOG';
export const CREATE_COMPANY_MEMBERSHIP = 'CREATE_COMPANY_MEMBERSHIP';
export const CREATE_COMPANY_MEMBERSHIP_SUCCESS = 'CREATE_COMPANY_MEMBERSHIP_SUCCESS';
export const CREATE_COMPANY_MEMBERSHIP_FAIL = 'CREATE_COMPANY_MEMBERSHIP_FAIL';
export const GET_COMPANY_INFO = 'GET_COMPANY_INFO';
export const UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO';
export const CHANGE_PAYMENT_PREFERENCE = 'CHANGE_PAYMENT_PREFERENCE';
export const STRIPE_AUTHORISATION_REQUESTED = 'STRIPE_AUTHORISATION_REQUESTED';
export const STRIPE_AUTHORISATION_SUCCEEDED = 'STRIPE_AUTHORISATION_SUCCEEDED';
export const STRIPE_AUTHORISATION_FAILED = 'STRIPE_AUTHORISATION_FAILED';

export const GET_BILLING_DATA = 'GET_BILLING_DATA';
export const BILLING_DATA_RETRIEVED = 'BILLING_DATA_RETRIEVED';
export const SET_STRIPE_BILLING = 'SET_STRIPE_BILLING';
export const STRIPE_BILLING_SUCCEEDED = 'STRIPE_BILLING_SUCCEEDED';
export const STRIPE_BILLING_FAILED = 'STRIPE_BILLING_FAILED';

export const SET_BILLING_AS_PROCESSING = 'SET_BILLING_AS_PROCESSING';

export const RUN_DB_HEALTH_CHECK = 'RUN_DB_HEALTH_CHECK';
export const RUN_DB_HEALTH_CHECK_SUCCESS = 'RUN_DB_HEALTH_CHECK_SUCCESS';
export const RUN_DB_HEALTH_CHECK_FAIL = 'RUN_DB_HEALTH_CHECK_FAIL';

export const RUN_DOUBLE_BOOKING_CHECK = 'RUN_DOUBLE_BOOKING_CHECK';
export const RUN_DOUBLE_BOOKING_CHECK_SUCCESS = 'RUN_DOUBLE_BOOKING_CHECK_SUCCESS';
export const RUN_DOUBLE_BOOKING_CHECK_FAIL = 'RUN_DOUBLE_BOOKING_CHECK_FAIL';

export const CREATE_COMPANY_REQUESTED = 'CREATE_COMPANY_REQUESTED';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL = 'CREATE_COMPANY_FAIL';
export const UPDATE_NEW_COMPANY_STORE = 'UPDATE_NEW_COMPANY_STORE';
export const UPDATE_NEW_COMPANY_BILLING_STORE = 'UPDATE_NEW_COMPANY_BILLING_STORE';
export const NEW_COMPANY_STORE_RESET = 'NEW_COMPANY_STORE_RESET';

export const COMPANY_MEMBERSHIPS_RETRIEVAL_REQUESTED = 'COMPANY_MEMBERSHIPS_RETRIEVAL_REQUESTED';
export const COMPANY_MEMBERSHIPS_RETRIEVAL_SUCCEEDED = 'COMPANY_MEMBERSHIPS_RETRIEVAL_SUCCEEDED';
export const COMPANY_MEMBERSHIPS_RETRIEVAL_FAILED = 'COMPANY_MEMBERSHIPS_RETRIEVAL_FAILED';
export const COMPANY_TAX_EXEMPT_MEMBERSHIPS_RETRIEVAL_SUCCEEDED = 'COMPANY_TAX_EXEMPT_MEMBERSHIPS_RETRIEVAL_SUCCEEDED';

export const GET_CURRENT_USERS_COMPANIES = 'GET_CURRENT_USERS_COMPANIES';
export const UPDATE_CURRENT_USERS_COMPANIES = 'UPDATE_CURRENT_USERS_COMPANIES';

export const UPDATED_COMPANY_INFO_STORE = 'UPDATED_COMPANY_INFO_STORE';
export const COMPANY_INFO_STORE_RESET = 'COMPANY_INFO_STORE_RESET';
export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';

export const UPDATE_BILLING = 'UPDATE_BILLING';
export const UPDATE_NEW_COMPANY_USER_STORE = 'UPDATE_NEW_COMPANY_USER_STORE';

export const UPDATE_COMPANY_PRODUCT = 'UPDATE_COMPANY_PRODUCT';
export const SAVE_COMPANY_PRODUCTS = 'SAVE_COMPANY_PRODUCTS';
export const UPDATE_COMPANY_PAY_LATER_SETTINGS = 'UPDATE_COMPANY_PAY_LATER_SETTINGS';

export const CREATE_BILLING = 'CREATE_BILLING';

export const initialState = {
  memberships: [],
  taxExemptMemberships: [],
  addons: [],
  companyInfo: {
    products: {},
    currencySym: '£',
    currency: 'GBP',
    timezone: 'Europe/London',
    stripeId: null,
    logoUrl: null,
    additionalPaymentMethods: [],
  },
  isLoading: false,
  tryingStripeAuthorisation: false,
  stripeAuthorisationMessage: null,
  userCompanies: [
    {
      userPreferences: null,
    },
  ],
  billing: {},
  billingCreationStatus: {
    requesting: false,
    processing: false,
    success: false,
    error: null,
  },
  newCompany: {
    name: null,
    logoFilename: null,
    timezone: 'Europe/London',
    currency: 'gbp',
    taxRate: 0.2,
    billing: {
      includeBilling: true,
      amount: 100,
      note: 'Your payment details are simply for verification. Billing will only begin on the agreed-upon date with our team.',
    },
    newUser: {},
    fullAddress: null,
    location: null,
    createXeroContact: true,
    hubspotCompanyId: null,
  },
  newCompanyCreationSuccess: null,
  newCompanyBillingLink: null,
  newCompanyMembership: {
    isNewMembershipDialogOpen: false,
    isCreatingCompanyMembershipRequest: false,
    isCreateCompanyMembershipSuccess: false,
    isCreateCompanyMembershipError: false,
  },
};

export const updateCompanyInfoStore = (companyInfo) => (
  { type: UPDATED_COMPANY_INFO_STORE, companyInfo }
);

export const createBilling = (billingParams) => (
  { type: CREATE_BILLING, billingParams }
);

export const updateCompanyProduct = (key, value) => (
  { type: UPDATE_COMPANY_PRODUCT, key, value }
);

export const saveCompanyProducts = (companyId) => (
  { type: SAVE_COMPANY_PRODUCTS, companyId }
);

export const updateBilling = (billingId, reqBody) => (
  { type: UPDATE_BILLING, billingId, reqBody }
);

export const getCurrentUsersCompanies = (userId) => (
  { type: GET_CURRENT_USERS_COMPANIES, userId });

export const updateCurrentUsersCompanies = (companies) => (
  { type: UPDATE_CURRENT_USERS_COMPANIES, companies });

export const requestCompanyMembershipRetrieval = (membershipType) => (
  { type: COMPANY_MEMBERSHIPS_RETRIEVAL_REQUESTED, membershipType }
);

export const succeedCompanyMembershipRetrieval = (memberships) => (
  { type: COMPANY_MEMBERSHIPS_RETRIEVAL_SUCCEEDED, memberships }
);
export const succeedCompanyTaxExemptMembershipRetrieval = (memberships) => (
  { type: COMPANY_TAX_EXEMPT_MEMBERSHIPS_RETRIEVAL_SUCCEEDED, memberships }
);
export const failCompanyMembershipRetrieval = () => (
  { type: COMPANY_MEMBERSHIPS_RETRIEVAL_FAILED }
);
export const updateCompanyPayLaterSettings = (payLaterSettings) => (
  { type: UPDATE_COMPANY_PAY_LATER_SETTINGS, payLaterSettings }
);

export function getCompanies() {
  return { type: GET_COMPANIES };
}

export function getCompanyInfo(onboarding = false, showAccountOwner = false) {
  return { type: GET_COMPANY_INFO, onboarding, showAccountOwner };
}

export function updateCompany(companyId, reqBody) {
  return { type: UPDATE_COMPANY, companyId, reqBody };
}

export function updateCompanyDetails(reqBody) {
  return { type: UPDATE_COMPANY_DETAILS, reqBody };
}

export function updateCompanyInfo(company) {
  return { type: UPDATE_COMPANY_INFO, company };
}

export function toggleNewMembershipDialog() {
  return { type: TOGGLE_NEW_MEMBERSHIP_DIALOG };
}

export function createCompanyMembership(reqBody) {
  return { type: CREATE_COMPANY_MEMBERSHIP, reqBody };
}

export function createCompanyMembershipSuccess() {
  return { type: CREATE_COMPANY_MEMBERSHIP_SUCCESS };
}

export function createCompanyMembershipFail() {
  return { type: CREATE_COMPANY_MEMBERSHIP_FAIL };
}

export function changePaymentPreference(preference) {
  return { type: CHANGE_PAYMENT_PREFERENCE, preference };
}

export function requestStripeAuthorisation(stripeAuthorisationCode) {
  return { type: STRIPE_AUTHORISATION_REQUESTED, stripeAuthorisationCode };
}

export function succeedStripeAuthorisation(stripeAuthorisationCode) {
  return { type: STRIPE_AUTHORISATION_SUCCEEDED, stripeAuthorisationCode };
}

export function failStripeAuthorisation(error) {
  return { type: STRIPE_AUTHORISATION_FAILED, error };
}

export function updateCompanies(companies) {
  return { type: UPDATE_COMPANIES, companies };
}

export function getBillingData(billingId) {
  return { type: GET_BILLING_DATA, billingId };
}
export const billingDataRetrieved = (billingData) => (
  { type: BILLING_DATA_RETRIEVED, billingData });

export function setStripeBilling(source) {
  return { type: SET_STRIPE_BILLING, source };
}
export function succeedStripeBilling(billingData) {
  return { type: STRIPE_BILLING_SUCCEEDED, billingData };
}
export function failStripeBilling(error) {
  return { type: STRIPE_BILLING_FAILED, error };
}

export function setBillingAsProcessing(billingId) {
  return { type: SET_BILLING_AS_PROCESSING, billingId };
}

export function runDbHealthCheck() {
  return { type: RUN_DB_HEALTH_CHECK };
}
export function runDbHealthCheckSuccess() {
  return { type: RUN_DB_HEALTH_CHECK_SUCCESS };
}
export function runDbHealthCheckFail() {
  return { type: RUN_DB_HEALTH_CHECK_FAIL };
}

export function runDoubleBookingCheck() {
  return { type: RUN_DOUBLE_BOOKING_CHECK };
}
export function runDoubleBookingCheckSuccess() {
  return { type: RUN_DOUBLE_BOOKING_CHECK_SUCCESS };
}
export function runDoubleBookingCheckFail() {
  return { type: RUN_DOUBLE_BOOKING_CHECK_FAIL };
}

export function createCompany() {
  return { type: CREATE_COMPANY_REQUESTED };
}
export function createCompanySuccess(billingLink) {
  return { type: CREATE_COMPANY_SUCCESS, billingLink };
}
export function createCompanyFail(error) {
  return { type: CREATE_COMPANY_FAIL, error };
}
export const updateNewCompanyStore = (value) => (
  { type: UPDATE_NEW_COMPANY_STORE, value }
);
export const updateNewCompanyBillingStore = (value) => (
  { type: UPDATE_NEW_COMPANY_BILLING_STORE, value }
);
export const updateNewCompanyUserStore = (value) => (
  { type: UPDATE_NEW_COMPANY_USER_STORE, value }
);
export const resetNewCompanyStore = () => (
  { type: NEW_COMPANY_STORE_RESET }
);

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_MEMBERSHIPS_RETRIEVAL_REQUESTED:
      return { ...state, isLoading: true };
    case COMPANY_MEMBERSHIPS_RETRIEVAL_SUCCEEDED:
      return { ...state, memberships: action.memberships, isLoading: false };
    case COMPANY_TAX_EXEMPT_MEMBERSHIPS_RETRIEVAL_SUCCEEDED:
      return { ...state, taxExemptMemberships: action.memberships, isLoading: false };
    case UPDATE_COMPANY_INFO:
      return {
        ...state,
        companyInfo: {
          ...action.company,
          currencySym: getCurrencySymbol(action.company.currency),
        },
      };
    case STRIPE_AUTHORISATION_REQUESTED:
      return { ...state, stripeAuthorisationMessage: 'Requesting Stripe authorisation...', tryingStripeAuthorisation: true };
    case STRIPE_AUTHORISATION_SUCCEEDED:
      return { ...state, stripeAuthorisationMessage: null, tryingStripeAuthorisation: false };
    case STRIPE_AUTHORISATION_FAILED:
      return {
        ...state,
        stripeAuthorisationMessage: action.error,
        tryingStripeAuthorisation: false,
      };
    case UPDATE_COMPANY_PRODUCT:
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          products: {
            ...state.companyInfo.products,
            [action.key]: action.value,
          },
        },
      };
    case BILLING_DATA_RETRIEVED:
      return {
        ...state,
        billing: action.billingData,
        billingCreationStatus: {
          ...state.billingCreationStatus,
          processing: action.billingData?.status === 'PROCESSING',
        },
      };
    case SET_STRIPE_BILLING:
      return {
        ...state,
        billingCreationStatus: {
          ...initialState.billingCreationStatus,
          requesting: true,
        },
      };
    case STRIPE_BILLING_SUCCEEDED:
      return {
        ...state,
        billing: action.billingData,
        billingCreationStatus: {
          ...state.billingCreationStatus,
          requesting: false,
          success: true,
        },
      };
    case STRIPE_BILLING_FAILED:
      return {
        ...state,
        billingCreationStatus: {
          ...state.paymentCreationStatus,
          requesting: false,
          error: action.error,
        },
      };
    case SET_BILLING_AS_PROCESSING:
      return {
        ...state,
        billingCreationStatus: {
          ...initialState.billingCreationStatus,
          processing: true,
        },
      };
    case UPDATED_COMPANY_INFO_STORE:
      return {
        ...state,
        companyInfo:
        {
          ...state.companyInfo,
          ...action.companyInfo,
        },
      };
    case UPDATE_NEW_COMPANY_STORE:
      return {
        ...state,
        newCompany:
          {
            ...state.newCompany,
            ...action.value,
          },
      };
    case UPDATE_CURRENT_USERS_COMPANIES: {
      const { companies } = action;
      return {
        ...state,
        userCompanies: companies,
      };
    }
    case UPDATE_NEW_COMPANY_BILLING_STORE:
      return {
        ...state,
        newCompany:
            {
              ...state.newCompany,
              billing: {
                ...state.newCompany.billing,
                ...action.value,
              },
            },
      };
    case UPDATE_NEW_COMPANY_USER_STORE:
      return {
        ...state,
        newCompany:
              {
                ...state.newCompany,
                newUser: {
                  ...state.newCompany.newUser,
                  ...action.value,
                },
              },
      };
    case NEW_COMPANY_STORE_RESET:
      return {
        ...state,
        newCompanyCreationSuccess: null,
        newCompanyBillingId: null,
        newCompany: { ...initialState.newCompany },
      };
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        newCompanyCreationSuccess: true,
        newCompanyBillingLink: action.billingLink,
      };
    case CREATE_COMPANY_FAIL:
      return {
        ...state,
        newCompanyCreationSuccess: false,
      };
    case TOGGLE_NEW_MEMBERSHIP_DIALOG:
      return {
        ...state,
        newCompanyMembership: {
          isNewMembershipDialogOpen: !state.newCompanyMembership.isNewMembershipDialogOpen,
          isCreatingCompanyMembershipRequest: false,
          isCreateCompanyMembershipSuccess: false,
          isCreateCompanyMembershipError: false,
        },
      };
    case CREATE_COMPANY_MEMBERSHIP: {
      return {
        ...state,
        newCompanyMembership: {
          ...state.newCompanyMembership,
          isCreatingCompanyMembershipRequest: true,
        },
      };
    }
    case CREATE_COMPANY_MEMBERSHIP_SUCCESS: {
      return {
        ...state,
        newCompanyMembership: {
          ...state.newCompanyMembership,
          isCreatingCompanyMembershipRequest: false,
          isCreateCompanyMembershipSuccess: true,
        },
      };
    }
    case CREATE_COMPANY_MEMBERSHIP_FAIL: {
      return {
        ...state,
        newCompanyMembership: {
          ...state.newCompanyMembership,
          isCreatingCompanyMembershipRequest: false,
          isCreateCompanyMembershipError: true,
        },
      };
    }
    default:
      return state;
  }
};
