/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Typography, IconButton, Modal, Box, TextField, Divider,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

export const PauseSalesCommunication = ({ companyInfo, updateCompany }) => {
  const dispatch = useDispatch();
  const { id: companyId, accounting, pbAdmins } = companyInfo;

  const [communicationPaused, setCommunicationPaused] = useState([]);
  const [openPausedModal, setOpenPausedModal] = useState(false);
  const [currentPause, setCurrentPause] = useState({ until: moment().add(30, 'days').format('YYYY-MM-DD'), note: 'New pause' }); // Default values for new pause
  const [teamMembers, setTeamMembers] = useState({});

  // Get the current user info from Redux
  const currentUser = useSelector((state) => state.currentUser.currentUser);

  const activePause = communicationPaused.find(
    (pause) => pause.type === 'ACTIVE'
      && pause.status === 'ACTIVE'
      && moment(pause.until, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day'),
  );

  useEffect(() => {
    if (accounting?.communicationPaused) {
      setCommunicationPaused(accounting.communicationPaused);
    }

    // Populate team member data from pbAdmins (id => name)
    if (pbAdmins && pbAdmins.length > 0) {
      const members = pbAdmins.reduce((acc, user) => {
        acc[user.userId] = `${user.firstName} ${user.lastName}`;
        return acc;
      }, {});
      setTeamMembers(members);
    }
  }, [accounting, pbAdmins]);

  useEffect(() => {
  }, [currentUser]);

  const saveChanges = (updatedPauses) => {
    const updatedAccounting = { ...accounting, communicationPaused: updatedPauses };
    dispatch(updateCompany(companyId, { accounting: updatedAccounting }));
  };

  const handleSavePausedCommunication = () => {
    if (!currentPause.note || currentPause.note.trim() === '') {
      alert('Note is required to save the communication pause.');
      return;
    }

    if (currentPause?.id) {
      // Editing existing pause
      const updatedPauses = communicationPaused.map(
        (pause) => (pause.id === currentPause.id ? currentPause : pause),
      );
      saveChanges(updatedPauses);
    } else {
      const newPause = {
        id: uuidv4(),
        until: currentPause.until,
        note: currentPause.note,
        status: 'ACTIVE',
        setBy: currentUser?.userId,
      };
      saveChanges([...communicationPaused, newPause]);
    }

    setOpenPausedModal(false);
    setCurrentPause({ until: moment().add(30, 'days').format('YYYY-MM-DD'), note: null });
  };

  const handleAddCommunicationPause = () => {
    if (activePause) {
      alert('There is already an active communication pause. Please edit or delete the existing pause.');
      return;
    }
    // Reset currentPause to default values for adding a new pause
    setCurrentPause({ until: moment().add(30, 'days').format('YYYY-MM-DD'), note: null });
    setOpenPausedModal(true); // Automatically open modal to add a new pause
  };

  const handleEditPause = (pauseId) => {
    const pauseToEdit = communicationPaused.find((pause) => pause.id === pauseId);
    setCurrentPause(pauseToEdit); // Set the current pause to the one being edited
    setOpenPausedModal(true); // Open modal for editing
  };

  const handleDeletePause = (pauseId) => {
    const updatedPauses = communicationPaused.map((pause) => (pause.id === pauseId ? { ...pause, status: 'DELETED' } : pause));
    saveChanges(updatedPauses);
  };

  return (
    <>
      <ConditionallyVisible condition={activePause}>
        <Typography variant="h6" gutterBottom>
          Communication Paused
        </Typography>
      </ConditionallyVisible>
      {communicationPaused.filter((pause) => (pause.status !== 'DELETED').length > 0
      && moment(pause.until, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day'))
        ? (
          communicationPaused
            .filter((pause) => pause.status !== 'DELETED'
        && moment(pause.until, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day'))
            .map((pause, index) => (
              <Box key={pause.id || index} mb={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                  <Box>
                    <Typography>
                      Communication paused until:
                      {' '}
                      {moment(pause.until, 'YYYY-MM-DD').format('DD-MMM-YYYY')}
                    </Typography>
                    <Typography>
                      Note:
                      {' '}
                      {pause.note}
                    </Typography>
                    <Typography>
                      Added By:
                      {' '}
                      {pause.setBy ? (teamMembers[pause.setBy] || 'Unknown User') : 'Not Set'}
                      {' '}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => handleEditPause(pause.id)} // Open modal for editing this pause
                      aria-label="edit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeletePause(pause.id)} // Clear button deletes this pause
                      aria-label="clear"
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Box>

                {/* Divider line between pauses */}
                {index < communicationPaused.filter((pause) => pause.status !== 'DELETED' && moment(pause.until, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day')).length - 1 && <Divider style={{ marginTop: '1rem' }} />}
              </Box>
            ))
        ) : (
          <Typography variant="body2">Communications are not paused.</Typography>
        )}

      <Button
        variant="outlined"
        color="primary"
        onClick={handleAddCommunicationPause}
        style={{ marginTop: '1rem' }}
        disabled={Boolean(activePause)}
      >
        Add a Communication Pause
      </Button>

      {/* Modal for Paused Communication */}
      <Modal
        open={openPausedModal}
        onClose={() => {
          setOpenPausedModal(false);
          setCurrentPause({ until: moment().add(30, 'days').format('YYYY-MM-DD'), note: 'New pause' }); // Reset the current pause when closing modal
        }}
        aria-labelledby="paused-communication"
      >
        <Box sx={modalStyle}>
          <Typography id="paused-communication" variant="h6" component="h2" gutterBottom>
            {currentPause?.id ? 'Edit Communication Pause' : 'Add Communication Pause'}
          </Typography>

          {/* Fields for Paused Communication */}
          <Box mb={2}>
            <TextField
              label="Until"
              type="date"
              value={currentPause?.until} // Pre-fill for new pause
              onChange={(e) => {
                const updatedPause = { ...currentPause, until: e.target.value };
                setCurrentPause(updatedPause);
              }}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              label="Note"
              value={currentPause?.note} // Pre-fill for new pause
              onChange={(e) => {
                const updatedPause = { ...currentPause, note: e.target.value };
                setCurrentPause(updatedPause);
              }}
              fullWidth
              multiline
              rows={2}
              style={{ marginTop: '1rem' }}
              required // Ensure this field is marked as required
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            type="button"
            style={{ marginTop: '1rem' }}
            onClick={handleSavePausedCommunication}
          >
            {currentPause?.id ? 'Save Changes' : 'Add Pause'}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PauseSalesCommunication;
