import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import CloseDialogIcon from '../../shared-components/CloseDialogIcon';
import { useCompany, useMobile } from '../../hooks';
import { editSubscriptionService } from '../../services/subscriptionsServices';

const validationSchema = Yup.object().shape({
  time: Yup.date().required('Time is required'),
});

const SubscriptionBillingDialog = ({
  isOpen, onClose, subscription, initialTime,
}) => {
  const { timezone } = useCompany();
  const queryClient = useQueryClient();
  const isMobile = useMobile();

  const time = moment.unix(initialTime).tz(timezone);

  const { mutate: updateBilling, isLoading } = useMutation({
    mutationFn: async (values) => {
      const nextBillingDate = time.clone().tz(timezone)
        .hours(moment(values.time).hours())
        .minutes(moment(values.time).minutes())
        .seconds(0)
        .milliseconds(0);

      return editSubscriptionService(
        subscription.companyId,
        subscription.id,
        {
          billingAnchor: nextBillingDate.toISOString(),
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['subscription', subscription.id]);
      onClose();
    },
  });

  const initialValues = useMemo(() => {
    if (!initialTime || !timezone) return {};

    return {
      time: moment.unix(initialTime).tz(timezone),
    };
  }, [initialTime, timezone]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      updateBilling(values);
    },
    enableReinitialize: true,
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="sm"
      fullWidth
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>Change Next Billing Time</DialogTitle>
        <DialogActions variant="none">
          <CloseDialogIcon onClick={onClose} />
        </DialogActions>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            {`Current Billing time is ${time.format('HH:mm A')}`}
          </Typography>

          <TimePicker
            value={formik.values.time}
            onChange={(time) => {
              formik.setFieldValue('time', time);
            }}
            showSecond={false}
            minuteStep={5}
            clearIcon={null}
            renderInput={(props) => (
              <TextField
                {...props}
                fullWidth
                variant="outlined"
                helperText={formik.touched.time && formik.errors.time}
                error={formik.touched.time && Boolean(formik.errors.time)}
              />
            )}
          />

          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginTop: '1rem' }}
          >
            {`Next billing will occur at: ${time.clone()
              .hours(moment(formik.values.time).hours())
              .minutes(moment(formik.values.time).minutes())
              .format('DD-MMM-YYYY, HH:mm A')}`}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading || !formik.isValid}
          >
            {isLoading ? 'Updating...' : 'Update Billing Time'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

SubscriptionBillingDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialTime: PropTypes.string.isRequired,
  subscription: PropTypes.shape().isRequired,
};

export default SubscriptionBillingDialog;
