/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';

const PitchbookingTeamMemberSelector = ({ value, onChange, label }) => {
  const [teamMembers, setTeamMembers] = useState([{ value: null, label: 'Loading...' }]);

  const companyInfo = useSelector((state) => state.companies.companyInfo);

  useEffect(() => {
    if (companyInfo.pbAdmins && companyInfo.pbAdmins.length > 0) {
      const managerList = companyInfo.pbAdmins
        .filter((user) => user.pbAdmin === true || user.userId === value)
        .map((user) => ({
          value: user.userId,
          name: `${user.firstName} ${user.lastName}`,
          label: (
            <span>
              {`${user.firstName} ${user.lastName}`}
              <span style={{ fontSize: '0.85em', color: 'grey' }}>
                {' '}
                (
                {user.email}
                )
              </span>
            </span>
          ),
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setTeamMembers(managerList);
    }
  }, [companyInfo, value]);

  return (
    <>
      <PBInput
        id="teamMember"
        type="select"
        label={label}
        value={value}
        options={teamMembers}
        onChange={(e) => onChange(e.target.value)}
      />
    </>
  );
};

export default PitchbookingTeamMemberSelector;
